import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupText,
    Label,
    Row
} from "reactstrap";
import {Btn, H4, H6} from "../../AbstractElements";
import React, {useEffect, useRef, useState} from "react";
import {handlerSetFormNumeric, handlerSetFormString} from "../../Utils/forms";
import axios from "axios";
import {API_CDN_DEFAULT, API_COURSE_MODULE, API_COURSE_MODULE_GROUPS} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {FilePond} from "react-filepond";
import {CourseModuleDataTable} from "../../Components/DataTables/CourseModuleDataTable";
import SweetAlert from "sweetalert2";
import {ExamTypeahead} from "../../Components/Typeahead/ExamTypeahead";
import DatePicker from "react-datepicker";
import {parseDate} from "../../Utils/date";

export const CourseModulesManageGroupForms = ({id = 0, courseID = 0, refresher, setRefresher}) => {
    const initialData = {
        title: "",
        course_id: courseID,
        participant_ids: [],
    }
    const [data, setData] = useState(initialData)
    const initialModuleData = {
        modules_name: "",
        modules_files_id: "",
        module_group_id: id,
        exam_id: 0,
        exam_time: 0,
        exam_due_date: new Date().toISOString(),
        exam_start_date: new Date().toISOString()
    }
    const [moduleData, setModuleData] = useState(initialModuleData)
    const [moduleFiles, setModuleFiles] = useState([])

    const [reference, setReference] = useState({
        module_files: useRef(null)
    })

    const [isUploading, setIsUploading] = useState(false)

    const loadModuleGroupData = () => [
        axios.get(API_COURSE_MODULE_GROUPS + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            id: id
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                console.log(json.data[0])
                json.data.length > 0 && setData(json.data[0])
            }
        }).finally(() => {
            setRefresher({...refresher, participant_datatable: true})
        })
    ]

    useEffect(() => {
        if (id !== 0) {
            setModuleData({
                ...moduleData,
                module_group_id: id
            })
            loadModuleGroupData()
        } else {
            setData(initialData)
        }
        setRefresher({...refresher, participant_datatable: true})

    }, [id])

    useEffect(() => {
        setData({
            ...initialData,
            course_id: courseID
        })
    }, [courseID])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id !== 0) {
            reference.module_files.current.processFile().then((f) => {
                setIsUploading(true)
                axios.post(API_COURSE_MODULE + "/", {
                    ...moduleData,
                    module_group_id: id,
                    modules_files_id: f.serverId
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Success!',
                            text: 'Data Saving Completed!',
                            icon: 'success'
                        }).then(() => {
                            setModuleData(initialModuleData)
                            setModuleFiles([])
                            // loadBatchData()
                        }).then(() => {
                            setRefresher({
                                ...refresher,
                                participant_datatable: true,
                                exam_typeahead: true,
                            })
                            // navigate(`${process.env.PUBLIC_URL}/manage/course-batch`)
                        })
                    } else {
                        throw r
                    }
                }).catch((err) => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Saving Failed!',
                        icon: 'error'
                    })
                }).finally(() => {
                    setIsUploading(false)
                })
            })


        }
    }

    const [files, setFiles] = useState([])
    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} Module Groups</H4>
            <hr/>
        </div>
        <Form className="form theme-form m-b-30" onSubmit={handlerSubmit}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Module Group Name</Label>
                        <Input required name="title" value={data.title} disabled={true}
                               onChange={(e) => handlerSetFormString(e, data, setData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>

                    <hr/>
                    <H6>Add Module Chapter</H6>
                    <FormGroup>
                        <Label>Module Chapter Name</Label>
                        <Input required name="modules_name" value={moduleData.modules_name}
                               onChange={(e) => handlerSetFormString(e, moduleData, setModuleData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>

                    <FormGroup>
                        <Label>Module Chapter Exam</Label>
                        <ExamTypeahead
                            courseID={data.course_id}
                            defaultID={moduleData.exam_id}
                            onChange={(e) => {
                                setModuleData({
                                    ...moduleData,
                                    exam_id: e.id
                                });
                            }}
                            refresh={refresher.exam_typeahead}
                            onRefreshFinish={() => setRefresher({
                                ...refresher,
                                exam_typeahead: false
                            })}/>
                    </FormGroup>

                    <Row>
                        <Col md={4} xs={12}>
                            <FormGroup>
                                <Label>Exam Time Limit</Label>
                                <InputGroup className="mb-3">
                                    <Input min={0} required name="exam_time" value={moduleData.exam_time}
                                           onChange={(e) => handlerSetFormNumeric(e, moduleData, setModuleData)}
                                           className="form-control btn-square" type="number"
                                           placeholder="Type Something"/>
                                    <InputGroupText>{'Minutes'}</InputGroupText>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col md={4} xs={12}>
                            <Label>Exam Start Date</Label>
                            <DatePicker className="form-control digits" selected={parseDate(moduleData.exam_start_date)}
                                        onChange={(date) => {
                                            setModuleData({
                                                ...moduleData,
                                                exam_start_date: date.toISOString()
                                            })
                                        }}/>
                        </Col>
                        <Col md={4} xs={12}>
                            <Label>Exam Due Date</Label>
                            <DatePicker className="form-control digits" selected={parseDate(moduleData.exam_due_date)}
                                        onChange={(date) => {
                                            setModuleData({
                                                ...moduleData,
                                                exam_due_date: date.toISOString()
                                            })
                                        }}/>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label>Module File</Label>
                        <FilePond
                            required
                            ref={reference.module_files}
                            files={moduleFiles}
                            allowMultiple={false}
                            onupdatefiles={(newFile) => {
                                setModuleFiles(newFile)
                            }}

                            instantUpload={false}
                            maxFiles={1}
                            imageCropAspectRatio={'3:4'}

                            maxFileSize={'10MB'}
                            server={API_CDN_DEFAULT}
                            name="files"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                type: 'submit'
            }}>
                Save
            </Btn>
        </Form>

        <hr/>
        <hr/>
        <H4>Module Chapter List</H4>
        <hr/>

        <Container fluid={true} className="datatables">
            <Row>
                <Col sm="12">
                    <Card>
                        {/*<CardHeader className="pb-0">*/}
                        {/*    <Row className="justify-content-between">*/}
                        {/*        <Col md={"auto"}>*/}
                        {/*            <H5>Manage Course Modules</H5>*/}
                        {/*        </Col>*/}
                        {/*        <Col md={"auto"}>*/}
                        {/*            <Btn attrBtn={{*/}
                        {/*                color: "secondary",*/}
                        {/*                className: "m-r-15",*/}
                        {/*                onClick: (e) => setDrawerState({...drawerState, forms: true, editId: 0})*/}
                        {/*            }}>New Modules Group</Btn>*/}
                        {/*        </Col>*/}
                        {/*    </Row>*/}
                        {/*</CardHeader>*/}
                        <CardBody>
                            <CourseModuleDataTable id={id} refresh={refresher.participant_datatable}
                                                   onRefreshFinish={() => {
                                                       setRefresher({...refresher, participant_datatable: false})
                                                   }}/>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

        {/*<CourseModuleDataTable id={id} refresh={refresher.participant_datatable}*/}
        {/*                       onRefreshFinish={() => {*/}
        {/*                           setRefresher({...refresher, participant_datatable: false})*/}
        {/*                       }}/>*/}
    </>
}