import {Breadcrumbs} from "../../AbstractElements";
import {Col, Container, Row} from "reactstrap";
import React, {useState} from "react";
import {SidenavManageSearchCourse} from "../../Components/Nav/SidenavManageUserCertificate";
import Drawer from "react-modern-drawer";
import {UserExamReportDrawer} from "./UserExamReportDrawer";
import {CourseBatchDataTable} from "./CourseBatchDataTable";

const ReportExamPage = () => {
    const [Sidebar, setSidebar] = useState(false);
    const OnClickHandel = () => {
        setSidebar(!Sidebar);
    };

    const [drawerState, setDrawerState] = useState({
        report: false,
        reportBatchID: 0
    })

    const closeAllDrawer = () => {
        setDrawerState({
            report: false,
            reportBatchID: 0
        })
    }

    const [selectedCourseID, setSelectedCourseID] = useState(0)

    const handleCourseChange = (courseID) => {
        setSelectedCourseID(parseInt(courseID))
    }

    return (
        <>
            <Breadcrumbs mainTitle="Manage Course Exam" parent="Course" title="Exam Report"/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-6 xl-30">
                            <div className="email-sidebar">
                                <a className="btn btn-primary email-sidebar-toggle" href="#" onClick={OnClickHandel}>Course
                                    Title</a>
                                <div className={`email-left-aside ${Sidebar ? 'open' : ''}`}>
                                    <SidenavManageSearchCourse onChange={handleCourseChange}/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-12 xl-70">
                            <div className="email-right-aside bookmark-tabcontent">
                                {selectedCourseID !== 0 &&
                                    <CourseBatchDataTable drawerState={drawerState} setDrawerState={setDrawerState}
                                                          courseId={selectedCourseID}/>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Drawer
                open={drawerState.report}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <UserExamReportDrawer id={drawerState.reportBatchID}/>
            </Drawer>
        </>
    )

}

export default ReportExamPage