import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardBody, Col, Form, FormGroup, Input, Media, Nav, NavItem} from 'reactstrap';
import {Btn, H6} from '../../AbstractElements';
import axios from "axios";
import {API_COURSE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {handlerSetFormString} from "../../Utils/forms";


export const SidenavManageSearchCourse = ({onChange, setSelectedCourseID}) => {
    const [activeCourseID, setActiveCourseID] = useState(1);

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const [filter, setFilter] = useState({
        title: ""
    })


    const loadCourseData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_COURSE + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setActiveCourseID(json.data.length > 0 ? json.data[0].id : 1)
                setSelectedCourseID(json.data.length > 0 ? json.data[0].id : 0)
            } else {
                setData([])
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadCourseData()
    }, [])

    const onHandleClick = (courseID) => {
        setActiveCourseID(courseID)
        return onChange(courseID)
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadCourseData(10, 0, filter)
    }

    return (
        <Fragment>
            <Card>
                <CardBody>
                    <div className="email-app-sidebar left-bookmark">
                        <Media>
                            <Media body>
                                <Form onSubmit={onSearchSubmit}>
                                    <FormGroup className="row">
                                        <H6 attrH6={{className: 'f-w-600'}}>Search Course</H6>
                                        <Col sm="12" className="m-t-5">
                                            <Input className="form-control" type="text" name="title"
                                                   placeholder={"Search Here"}
                                                   onChange={(e) => handlerSetFormString(e, filter, setFilter)}/>
                                        </Col>
                                        <Col sm="12">
                                            <Btn attrBtn={{
                                                color: "primary",
                                                className: "m-t-10 w-100",
                                                type: "submit"
                                            }}>Search</Btn>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Media>
                        </Media>

                        <Nav className="main-menu" role="tablist">
                            <li>
                                <hr/>
                            </li>
                            <NavItem>
                                <span className="main-title">Courses</span>
                            </NavItem>
                            {data.length > 0 ? data.map((v, i) => {
                                    return (
                                        <NavItem>
                                            <a href="#" className={activeCourseID === v.id ? 'active' : ''}
                                               onClick={() => onHandleClick(v.id)}>
                                                <span className="title"> {v.title} </span></a>
                                        </NavItem>
                                    )
                                }) :
                                <NavItem>
                                    <h5 style={{fontSize: "1.2em", textAlign: "center"}} className="m-t-5">No Course
                                        Found</h5>
                                </NavItem>}
                            {data.length > 0 && <NavItem>
                                <h5 style={{fontSize: "0.8em", textAlign: "center"}} className="m-t-5">Use Search to
                                    Show More</h5>
                            </NavItem>}
                        </Nav>
                    </div>
                </CardBody>
            </Card>
        </Fragment>
    );
};