import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../../AbstractElements";
import React, {useEffect, useState} from "react";
import {handlerSetFormString} from "../../../Utils/forms";
import axios from "axios";
import {API_COURSE_BATCH_CLASS} from "../../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {parseDate} from "../../../Utils/date";

export const CourseClassForms = ({id = 0, batchID = 0, onFinish}) => {
    const initialData = {
        title: "",
        speaker: "",
        url: "",
        date: new Date().toISOString(),
        batch_id: 0
    }

    const [data, setData] = useState(initialData)

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_COURSE_BATCH_CLASS + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setData(json.data[0])
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    useEffect(() => {
        setData({
            ...initialData,
            batch_id: batchID
        })
    }, [batchID])


    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id === 0) {
            axios.post(API_COURSE_BATCH_CLASS + "/", {...data}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        setData(initialData)
                        onFinish()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        } else {
            axios.put(API_COURSE_BATCH_CLASS + "/", {...data}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        setData(initialData)
                        onFinish()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        }
    }

    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} Course Batch</H4>
            <hr/>
        </div>
        <Form>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Topic</Label>
                        <Input required name="title" value={data.title}
                               onChange={(e) => handlerSetFormString(e, data, setData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Speaker</Label>
                        <Input required name="speaker" value={data.speaker}
                               onChange={(e) => handlerSetFormString(e, data, setData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>URL</Label>
                        <Input required name="url" value={data.url}
                               onChange={(e) => handlerSetFormString(e, data, setData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Date</Label>
                        <DatePicker className="form-control digits" selected={parseDate(data.date)}
                                    showTimeSelect
                                    dateFormat="dd-MM-yyyy h:mm aa"
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            date : e.toISOString()
                                        })
                                    }}/>
                        {/*<Input*/}
                        {/*    name={"date"}*/}
                        {/*    className="form-control digits"*/}
                        {/*    type="datetime-local"*/}
                        {/*    value={data.date}*/}

                        {/*    onChange={(e) => {*/}
                        {/*        setData({*/}
                        {/*            ...data,*/}
                        {/*            date: e.target.value*/}
                        {/*        })*/}
                        {/*    }}*/}
                        {/*/>*/}
                    </FormGroup>
                </Col>
            </Row>
            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                // type: 'submit',
                onClick: (e) => {
                    handlerSubmit(e)
                }
            }}>
                Add Class
            </Btn>
        </Form>
    </>
}