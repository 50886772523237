import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";

import React, {useState} from "react";
import {SidenavManageSearchCourse} from "../../Components/Nav/SidenavManageUserCertificate";
import Drawer from "react-modern-drawer";
import {CourseBatchForms} from "./CourseBatchManageForms/CourseBatchForms";
import {CourseBatchManageForms} from "./CourseBatchManageForms";
import {CourseBatchDataTable} from "../../Components/DataTables/CourseBatchDataTable";

const ManageCourseBatchPage = () => {
    const [Sidebar, setSidebar] = useState(false);
    const OnClickHandel = () => {
        setSidebar(!Sidebar);
    };

    const [drawerState, setDrawerState] = useState({
        forms: false,
        assignUserForms: false,
        editId: 0
    })

    const closeAllDrawer = () => {
        setDrawerState({
            forms: false,
            assignUserForms: false,
            editId: 0
        })

        setRefresher({...refresher, cb_datatable: true})
    }

    const [refresher, setRefresher] = useState({
        cb_datatable: false,
    })

    const [selectedCourseID, setSelectedCourseID] = useState(0)

    const handleCourseChange = (courseID) => {
        setSelectedCourseID(parseInt(courseID))
    }

    return (
        <>
            <Breadcrumbs mainTitle="Manage Course Batch" parent="Course" title="Manage Course Batch"/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-6 xl-30">
                            <div className="email-sidebar">
                                <a className="btn btn-primary email-sidebar-toggle" href="#" onClick={OnClickHandel}>Course
                                    Title</a>
                                <div className={`email-left-aside ${Sidebar ? 'open' : ''}`}>
                                    <SidenavManageSearchCourse onChange={handleCourseChange}/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-12 xl-70">
                            <div className="email-right-aside bookmark-tabcontent">

                                {selectedCourseID !== 0 && <Card>
                                    <CardHeader className="pb-0">
                                        <Row className="justify-content-between">
                                            <Col md={"auto"}>
                                                <H5>Course Batch</H5>
                                            </Col>
                                            <Col md={"auto"}>
                                                <Btn attrBtn={{
                                                    color: "secondary",
                                                    className: "m-r-15",
                                                    onClick: (e) => setDrawerState({
                                                        ...drawerState,
                                                        forms: true,
                                                        editId: 0
                                                    })
                                                }}>New Batch</Btn>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                        <CourseBatchDataTable courseId={selectedCourseID} drawerState={drawerState}
                                                              setDrawerState={setDrawerState}
                                                              refresh={refresher.cb_datatable} onRefreshFinish={() => {
                                            setRefresher({...refresher, cb_datatable: false})
                                        }}/>
                                    </CardBody>
                                </Card>}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>

            <Drawer
                open={drawerState.forms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CourseBatchForms id={drawerState.editId} courseID={selectedCourseID}
                                  onCloseDrawer={() => closeAllDrawer()}/>
            </Drawer>

            <Drawer
                open={drawerState.assignUserForms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CourseBatchManageForms id={drawerState.editId} courseID={selectedCourseID}/>
            </Drawer>

        </>
    )

}

export default ManageCourseBatchPage