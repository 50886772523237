import {Link, useNavigate} from "react-router-dom";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_CDN_DEFAULT, API_CONTENT_SERVICE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import {handlerSetFormString} from "../../Utils/forms";

import {FilePond} from "react-filepond";

export const ServiceForms = ({id = 0}) => {
    const initialData = {
        id: 0,
        title: "",
        description: "",
        apply_wa_number: "",
        brochure_file_id: "",
        schedule_file_id: ""
    }

    const [data, setData] = useState(initialData)
    const [editorData, setEditorData] = useState("")
    const [brochureFile, setBrochureFile] = useState([])
    const [scheduleFile, setScheduleFile] = useState([])

    const navigate = useNavigate()
    const [reference, setReference] = useState({
        fp_brochure: useRef(null),
        fp_schedule: useRef(null)
    })

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_CONTENT_SERVICE + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    if (json.data.length > 0) {
                        setData(json.data[0])
                        setEditorData(json.data[0].event_content)
                        if (json.data[0].brochure_file_id !== "") {
                            setBrochureFile([{
                                source: json.data[0].cover_image_id,
                                options: {type: 'local'}
                            }])
                        }
                        if (json.data[0].schedule_file_id !== "") {
                            setScheduleFile([{
                                source: json.data[0].cover_image_id,
                                options: {type: 'local'}
                            }])
                        }
                    }
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    const handlerSubmit = (e) => {
        e.preventDefault()

        const scheduleUploader = reference.fp_schedule.current.processFile()
            .then((f) => {
                return f.serverId
            })

        const brochureUploader = reference.fp_brochure.current.processFile()
            .then((f) => {
                return f.serverId
            })


        if (id === 0) {
            Promise.all([scheduleUploader, brochureUploader])
                .then((serverIDs) => {
                    axios.post(API_CONTENT_SERVICE + "/", {
                        ...data,
                        brochure_file_id: serverIDs[1],
                        schedule_file_id: serverIDs[0],
                    }).then(r => {
                        if (r.status === 204) {
                            SweetAlert.fire({
                                title: 'Success!',
                                text: 'Data Saving Completed!',
                                icon: 'success'
                            }).then(() => {
                                navigate(`${process.env.PUBLIC_URL}/manage/services`)
                            })
                        } else {
                            throw r
                        }
                    }).catch((err) => {
                        SweetAlert.fire({
                            title: 'Failed!',
                            text: 'Data Saving Failed!',
                            icon: 'error'
                        })
                    })
                })

        } else {
            Promise.all([scheduleUploader, brochureUploader])
                .then((serverIDs) => {
                    axios.put(API_CONTENT_SERVICE + "/", {
                        ...data,
                        brochure_file_id: serverIDs[1],
                        schedule_file_id: serverIDs[0],
                    }).then(r => {
                        if (r.status === 204) {
                            SweetAlert.fire({
                                title: 'Success!',
                                text: 'Data Saving Completed!',
                                icon: 'success'
                            }).then(() => {
                                navigate(`${process.env.PUBLIC_URL}/manage/services`)
                            })
                        } else {
                            throw r
                        }
                    }).catch((err) => {
                        SweetAlert.fire({
                            title: 'Failed!',
                            text: 'Data Saving Failed!',
                            icon: 'error'
                        })
                    })
                })
        }
    }

    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} Services</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <FormGroup>
                <Label>Title</Label>
                <Input required name="title" value={data.title}
                       onChange={(e) => handlerSetFormString(e, data, setData)}
                       className="form-control btn-square" type="text"
                       placeholder="Please Type Something"/>
            </FormGroup>
            <FormGroup>
                <Label>Description</Label>
                <Input required name="description" value={data.description}
                       onChange={(e) => handlerSetFormString(e, data, setData)}
                       className="form-control btn-square" type="textarea"
                       placeholder="Please Type Something"/>
            </FormGroup>
            <FormGroup>
                <Label>Apply Whatsapp Number</Label>
                <Input required name="apply_wa_number" value={data.apply_wa_number}
                       onChange={(e) => handlerSetFormString(e, data, setData)}
                       className="form-control btn-square" type="text"
                       placeholder="Please Start With Country Code instead 0"/>
            </FormGroup>
            <Row>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <Label>Brochure File</Label>
                        <FilePond
                            required
                            files={brochureFile}
                            allowMultiple={false}
                            ref={reference.fp_brochure}
                            onupdatefiles={(newFile) => {
                                setBrochureFile(newFile)
                            }}

                            instantUpload={false}
                            maxFiles={1}

                            allowImageCrop={true}
                            allowImageTransform={true}
                            allowFileSizeValidation={true}

                            maxFileSize={'2MB'}
                            server={API_CDN_DEFAULT}
                            name="files"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                        />
                    </FormGroup>
                </Col>
                <Col md={6} sm={12}>
                    <FormGroup>
                        <Label>Schedule File</Label>
                        <FilePond
                            required
                            files={scheduleFile}
                            allowMultiple={false}
                            ref={reference.fp_schedule}
                            onupdatefiles={(newFile) => {
                                setScheduleFile(newFile)
                            }}

                            instantUpload={false}
                            maxFiles={1}

                            allowImageCrop={true}
                            allowImageTransform={true}
                            allowFileSizeValidation={true}

                            maxFileSize={'2MB'}
                            server={API_CDN_DEFAULT}
                            name="files"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                        />
                    </FormGroup>
                </Col>
            </Row>

            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                type: 'submit',
            }}
            >Submit</Btn>
            <Link to={`${process.env.PUBLIC_URL}/manage/services`}>
                <Btn attrBtn={{color: 'light'}}>Cancel</Btn>
            </Link>
        </Form>
    </>
}