import {FilePond} from "react-filepond";
import {API_CDN_DEFAULT, API_USER_CERTIFICATE} from "../../api/service";
import React, {useEffect, useState} from "react";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import axios from "axios";
import {toast} from "react-toastify";

export const FileUploadCertificate = ({userId, batchId}) => {
    const initialData = {
        id: 0,
        user_id: 0,
        batch_id: 0,
        certificate_files_id: ""
    }
    const [userCertificateFiles, setUserCertificateFiles] = useState([])
    const [data, setData] = useState(initialData)

    useEffect(() => {
        axios.get(`${API_USER_CERTIFICATE}/?pagination=${CreatePaginationEncoding(1, 0)}&filter=${CreateFilterEncoding({
            user_id: userId,
            batch_id: batchId
        })}`, {withCredentials: true})
            .then((res) => {
                if (res.status === 200) {
                    return res.data
                }
            })
            .then((json) => {
                if (json.data["data"] !== null) {
                    setData({
                        ...json.data["data"][0]
                    })
                    setUserCertificateFiles([{
                        source: json.data["data"][0]["certificate_files_id"],
                        options: {type: 'local'}
                    }])
                }
            })

    }, [])

    const handleFinishProcess = (err, file) => {
        if (err !== null) {
            toast.error('We\'ve Some Error !', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else {
            if (data.id !== 0) {
                axios.put(`${API_USER_CERTIFICATE}/`, {
                    ...data,
                    certificate_files_id: file.serverId
                }, {withCredentials: true})
                    .then(() => {
                        toast.success('Success Save Certificate !', {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    })
                    .catch(() => {
                        toast.error('Failed Save Certificate !', {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    })
            } else {
                axios.post(`${API_USER_CERTIFICATE}/`, {
                    ...data,
                    user_id: userId,
                    batch_id: batchId,
                    certificate_files_id: file.serverId
                }, {withCredentials: true})
                    .then(() => {
                        toast.success('Success Save Certificate !', {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    })
                    .catch(() => {
                        toast.error('Failed Save Certificate !', {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    })
            }
        }
    }

    const handleRemoveProcess = (err, file) => {
        if (err !== null) {
            toast.error('We\'ve Some Error !', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } else {
            if (data.id !== 0) {
                axios.delete(`${API_USER_CERTIFICATE}/?id=${data.id}`, {withCredentials: true})
                    .then(() => {
                        setData(initialData)
                        setUserCertificateFiles([])

                        toast.success('Success Delete Certificate !', {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    })
                    .catch(() => {
                        toast.error('Failed Delete Certificate !', {
                            position: toast.POSITION.BOTTOM_LEFT
                        });
                    })
            }
        }
    }

    return <div style={{
        width: "500px",
        padding: "6px 12px"
    }}>
        <FilePond
            required
            files={userCertificateFiles}
            allowMultiple={false}

            onremovefile={handleRemoveProcess}
            onprocessfile={handleFinishProcess}
            onupdatefiles={(newFile) => {
                setUserCertificateFiles(newFile)
            }}

            maxFiles={1}
            acceptedFileTypes={['image/*']}

            allowImagePreview={false}
            maxFileSize={'2MB'}
            server={API_CDN_DEFAULT}
            name="files"
            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
        />
    </div>
}
