import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_USER} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";


export const UserTypeahead = ({onChange, refresh = false, onRefreshFinish, exceptIds = [], ignoreIDs = []}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [selectedUser, setSelectedUser] = useState([])

    useEffect(() => {
        if (refresh === true) {
            setSelectedUser([])
            onRefreshFinish()
        }
    }, [refresh])

    const loadUserData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_USER + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding({
            ...filter,
            except_ids: exceptIds
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setOptions(json.data.length > 0 ? json.data : [])
            } else {
                setOptions([])
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleInputChange = (e, m) => {
        loadUserData(10, 0, {
            full_name: e,
            not_ids : ignoreIDs
        })
    }

    const handleOnChange = (newValue) => {
        setSelectedUser(newValue)
        onChange(newValue)
    }


    return (
        <>
            <Select
                // filterOption={createFilter({
                //     stringify: opt => `${opt.id}|${opt.full_name}`
                // })}
                getOptionLabel={(opt) => `${opt.full_name} (${opt.id})`}
                value={selectedUser}
                getOptionValue={(opt) => `${opt.full_name} (${opt.id})`}
                onChange={(newValue, m) => {
                    handleOnChange(newValue)
                }}
                onInputChange={handleInputChange}
                options={options}
                loadOption={isLoading}
                className="js-example-basic-single col-sm-12"
                isMulti/>
        </>
    )
}
