import DataTable from "react-data-table-component";
import {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import axios from "axios";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_COURSE_BATCH_CLASS} from "../../api/service";
import {CountPaginationOffset} from "../../Utils/pagination";
import {TableActionStyle} from "../../Utils/table";
import {useNavigate} from 'react-router-dom';

export const AbsenceDataTable = ({date, drawerState, setDrawerState}) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState({
        title: "",
        date: date
    })

    const navigate = useNavigate()

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Topic',
            selector: (row) => row.title,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.batch_id, row.id),
            sortable: true,
            center: true,
        },
    ]

    const tableAction = (batch_id, class_id) => {
        return (<div>
                    <span>
                        <Btn attrBtn={{
                            onClick: () => {
                                setDrawerState({
                                    ...drawerState,
                                    batch_id: batch_id,
                                    class_id: class_id,
                                    forms: true,
                                })
                            },
                            style: TableActionStyle,
                            className: 'btn btn-primary btn-xs',
                            type: 'button'
                        }}>Absence</Btn>
                    </span>
        </div>)
    }


    const loadData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_COURSE_BATCH_CLASS + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        setFilter({
            ...filter,
            date: date
        })
        loadData(pagination.rowsPerPage, 0, {
            ...filter,
            date: date
        })
    }, [date])

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadData(newPerPage, CountPaginationOffset(page, newPerPage))
        setPagination({
            ...pagination, page: page, rowsPerPage: newPerPage
        })
    }

    return <>
        <Col md={12}>
            <Form onSubmit={onSearchSubmit}>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Search</Label>
                            <Col sm="12">
                                <Input className="form-control" type="text" placeholder={"Search Here"}
                                       onChange={(e) => {
                                           setFilter({
                                               ...filter,
                                               title: e.target.value
                                           })
                                       }}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{color: "primary", className: "m-r-15", type: "submit"}}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>

        <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>

    </>
}