import AccountPage from "../Pages/Account";
import {Navigate} from "react-router-dom";
import ManageUserPage from "../Pages/ManageUserPage";
import ManageCoursePage from "../Pages/ManageCoursePage";
import ManageEventPage from "../Pages/ManageEventPage";
import ManageProjectPage from "../Pages/ManageProjectPage";
import ManageCourseBatchPage from "../Pages/ManageCourseBatchPage";
import ManageCourseExamPage from "../Pages/ManageCourseExamPage";
import ManageUserCertificatePage from "../Pages/ManageUserCetificatePage";
import ManageCourseModulePage from "../Pages/ManageCourseModulePage";
import AbsencePage from "../Pages/AbsencePage";
import ReportExamPage from "../Pages/ReportExamPage";
import LoginPages from "../Pages/Login/LoginForm";
import ManageServicePage from "../Pages/ManageServicePage";
import ManageStatisticPage from "../Pages/ManageStatisticPage";
import ManageTestimonialPage from "../Pages/ManageTestimonialPage";

export const routes = [
    {
        path: `${process.env.PUBLIC_URL}/`, Component: () => {
            return <Navigate to={`${process.env.PUBLIC_URL}/account`}/>
        }
    },


    {path: `${process.env.PUBLIC_URL}/manage/user`, Component: <ManageUserPage/>},
    {path: `${process.env.PUBLIC_URL}/manage/user-certificate`, Component: <ManageUserCertificatePage/>},
    {path: `${process.env.PUBLIC_URL}/manage/course`, Component: <ManageCoursePage/>},
    {path: `${process.env.PUBLIC_URL}/manage/course/absence`, Component: <AbsencePage/>},
    {path: `${process.env.PUBLIC_URL}/manage/course-batch`, Component: <ManageCourseBatchPage/>},
    {path: `${process.env.PUBLIC_URL}/manage/course-modules`, Component: <ManageCourseModulePage/>},
    {path: `${process.env.PUBLIC_URL}/manage/event`, Component: <ManageEventPage/>},
    {path: `${process.env.PUBLIC_URL}/manage/services`, Component: <ManageServicePage/>},
    {path: `${process.env.PUBLIC_URL}/manage/statistic`, Component: <ManageStatisticPage/>},
    {path: `${process.env.PUBLIC_URL}/manage/exam`, Component: <ManageCourseExamPage/>},
    {path: `${process.env.PUBLIC_URL}/manage/project`, Component: <ManageProjectPage/>},
    {path: `${process.env.PUBLIC_URL}/manage/testimonial`, Component: <ManageTestimonialPage/>},
    // {path: `${process.env.PUBLIC_URL}/manage/training`, Component: <ManageTrainingPage/>},

    {path: `${process.env.PUBLIC_URL}/report/exam`, Component: <ReportExamPage/>},

    //
    // {path: `${process.env.PUBLIC_URL}/exam`, Component: <ExamListPage/>},
    // {path: `${process.env.PUBLIC_URL}/exam/:session`, Component: <ExamPage/>},
    // {path: `${process.env.PUBLIC_URL}/certificate`, Component: <CertificatePage/>},
    {path: `${process.env.PUBLIC_URL}/account`, Component: <AccountPage/>},
];

export const unprotectedRoutes = [
    {path: `${process.env.PUBLIC_URL}/login`, Component: <LoginPages/>},
]
