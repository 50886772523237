import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import {TableActionStyle} from "../../Utils/table";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {API_COURSE_MODULE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {CountPaginationOffset} from "../../Utils/pagination";
import {useNavigate} from 'react-router-dom';

export const CourseModuleDataTable = ({id = 0, refresh = false, onRefreshFinish}) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })

    const nav = useNavigate();

    const [filter, setFilter] = useState({
        modules_name: "",
        module_group_id: id
    })

    useEffect(() => {
        setFilter({
            ...filter,
            module_group_id: id
        })
    }, [id])


    useEffect(() => {
        if (refresh === true) {
            loadCourseModulesData(pagination.rowsPerPage, CountPaginationOffset(pagination.page, pagination.rowsPerPage), {
                ...filter,
                module_group_id: id
            })
            onRefreshFinish()
        }
    }, [refresh])


    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
        },
        {
            name: 'Chapter Module Name',
            selector: (row) => row.modules_name,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row),
            sortable: true,
            center: true,
        },
    ]

    const tableAction = (row) => {
        return (
            <div>
                <span>
                    <Btn attrBtn={{
                        onClick: () => handleDelete(row.id),
                        style: TableActionStyle,
                        className: 'btn btn-danger btn-xs',
                        type: 'button'
                    }}>Delete</Btn>
                </span>

                {/*&nbsp;&nbsp;*/}
                {/*<span>*/}
                {/*    <Btn attrBtn={{*/}
                {/*        onClick: () => {*/}
                {/*            setDrawerState({*/}
                {/*                ...drawerState,*/}
                {/*                forms: true,*/}
                {/*                editId: id*/}
                {/*            })*/}
                {/*        }, style: TableActionStyle, className: 'btn btn-success btn-xs', type: 'button'*/}
                {/*    }}>Edit</Btn>*/}
                {/*</span> */}

                &nbsp;&nbsp;
                <span>
                    <Btn attrBtn={{
                        onClick: () => {
                            window.open(`${process.env.REACT_APP_API_HOST}/cdn/default/download?load=${row.modules_files_id}`, '_blank');
                        }, style: TableActionStyle, className: 'btn btn-primary btn-xs', type: 'button'
                    }}>Download</Btn>
                </span>
            </div>
        )
    }

    const handleDelete = (deleteID) => {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.delete(API_COURSE_MODULE + "/?id=" + deleteID).then(r => {
                    if (r.status === 200) {
                        return r.data["data"]
                    }
                }).then(json => {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Deletion Completed!',
                        icon: 'success'
                    }).then(() => {
                        loadCourseModulesData(10, 0, {...filter, module_group_id: id})
                    })
                }).catch(() => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Deletion Failed!',
                        icon: 'error'
                    })
                })
            }
        });
    }


    const loadCourseModulesData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_COURSE_MODULE + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadCourseModulesData(10, 0, {...filter, module_group_id: id})
    }, [id])

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadCourseModulesData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadCourseModulesData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };


    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadCourseModulesData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    }

    return <>
        <Col md={12}>
            <Form onSubmit={onSearchSubmit}>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Search</Label>
                            <Col sm="12">
                                <Input className="form-control" type="text" onChange={(e) => {
                                setFilter({...filter, modules_name: e.target.value})}}
                                       placeholder={"Search Here"}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{
                                color: "primary",
                                className: "m-r-15",
                                type: "submit"
                            }}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>

        <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>
    </>
}