import {
    BarChart,
    Bookmark,
    BookOpen,
    Briefcase,
    Layers,
    LifeBuoy,
    Minimize,
    Server,
    Tag,
    User,
    Zap
} from 'react-feather';

export const MENUITEMS = [
    {
        menutitle: 'Manage',
        menucontent: 'ManageUser,ManageUserCertificate',
        Items: [
            {
                title: 'User', icon: User, type: 'link', path: `${process.env.PUBLIC_URL}/manage/user`
            },
            {
                title: 'User Certificate',
                icon: BookOpen,
                type: 'link',
                path: `${process.env.PUBLIC_URL}/manage/user-certificate`
            },
        ]
    },
    {
        menutitle: 'Report',
        menucontent: 'ExamReport',
        Items: [
            {
                title: 'Exam Report', icon: Bookmark, type: 'link', path: `${process.env.PUBLIC_URL}/report/exam`
            },
        ]
    },
    {
        menutitle: 'Course',
        menucontent: 'Course, CourseBatch, Absence, ManageCourse, CourseModules CourseExam',
        Items: [
            {
                title: 'Absence', icon: Tag, type: 'link', path: `${process.env.PUBLIC_URL}/manage/course/absence`
            },
            {
                title: 'Manage Course', icon: LifeBuoy, type: 'link', path: `${process.env.PUBLIC_URL}/manage/course`
            },
            {
                title: 'Course Batch', icon: Layers, type: 'link', path: `${process.env.PUBLIC_URL}/manage/course-batch`
            },
            {
                title: 'Course Modules',
                icon: Briefcase,
                type: 'link',
                path: `${process.env.PUBLIC_URL}/manage/course-modules`
            },
            {
                title: 'Course Exam', icon: BookOpen, type: 'link', path: `${process.env.PUBLIC_URL}/manage/exam`
            },
        ]
    },
    {
        menutitle: 'Content',
        menucontent: 'Event,Projects,Training',
        Items: [
            {
                title: 'Event', icon: Zap, type: 'link', path: `${process.env.PUBLIC_URL}/manage/event`
            },
            {
                title: 'Service', icon: Server, type: 'link', path: `${process.env.PUBLIC_URL}/manage/services`
            },
            {
                title: 'Statistic', icon: BarChart, type: 'link', path: `${process.env.PUBLIC_URL}/manage/statistic`
            },
            {
                title: 'Testimonial', icon: Minimize, type: 'link', path: `${process.env.PUBLIC_URL}/manage/testimonial`
            },
            // {
            //     title: 'Training', icon: BookOpen, type: 'link', path: `${process.env.PUBLIC_URL}/manage/training`
            // },
        ]
    },
    {
        Items: [
            {
                title: 'Account', icon: User, type: 'link', path: `${process.env.PUBLIC_URL}/account`
            },
        ]
    },
]