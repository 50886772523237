import DataTable from "react-data-table-component";
import {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import {TableActionStyle} from "../../Utils/table";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {API_COURSE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {CountPaginationOffset} from "../../Utils/pagination";

export const CourseDataTable = ({
                                    drawerState,
                                    setDrawerState,
                                    refresh = false,
                                    onRefreshFinish,
                                    customColumns,
                                    dataLoadURL = API_COURSE + "/",
                                    dataLoadFilter
                                }) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [filter, setFilter] = useState({
        title: ""
    })
    const [columns, setColumns] = useState([
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
        },
        {
            name: 'Course Title',
            selector: (row) => row.title,
            sortable: true,
            center: true,
        },
        {
            name: 'Batch Count',
            // selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.id),
            sortable: true,
            center: true,
        },
    ])

    const tableAction = (id) => {
        return (<div>
                    <span>
                        <Btn attrBtn={{
                            onClick: () => handleDelete(id),
                            style: TableActionStyle,
                            className: 'btn btn-danger btn-xs',
                            type: 'button'
                        }}>Delete</Btn>
                    </span> &nbsp;&nbsp;
            <span>
                        <Btn attrBtn={{
                            onClick: () => {
                                setDrawerState({
                                    ...drawerState,
                                    forms: true,
                                    editId: id
                                })
                            }, style: TableActionStyle, className: 'btn btn-success btn-xs', type: 'button'
                        }}>Edit </Btn>
                    </span>
        </div>)
    }

    const handleDelete = (id) => {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.delete(API_COURSE + "/?id=" + id).then(r => {
                    if (r.status === 200) {
                        return r.data["data"]
                    }
                }).then(json => {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Deletion Completed!',
                        icon: 'success'
                    }).then(() => {
                        loadCourseData()
                    })
                }).catch(() => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Deletion Failed!',
                        icon: 'error'
                    })
                })
            }
        });
    }

    const loadCourseData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(dataLoadURL + "?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(dataLoadFilter !== undefined && dataLoadFilter !== null ? dataLoadFilter : filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadCourseData()
    }, [])

    useEffect(() => {
        if (refresh === true) {
            loadCourseData(pagination.rowsPerPage, CountPaginationOffset(pagination.page, pagination.rowsPerPage), {
                ...filter
            })
            onRefreshFinish()
        }
    }, [refresh])

    useEffect(() => {
        if (customColumns !== undefined && customColumns !== null) {
            setColumns(customColumns)
        }
    }, [customColumns])


    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadCourseData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadCourseData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };


    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadCourseData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    }

    return <>
        <Col md={12}>
            <Form onSubmit={onSearchSubmit}>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Search</Label>
                            <Col sm="12">
                                <Input onChange={(e) => {
                                    setFilter({
                                        ...filter,
                                        title: e.target.value
                                    })
                                }
                                } className="form-control" type="text" placeholder={"Search Here"}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{color: "primary", className: "m-r-15", type: "submit"}}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>

        <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>
    </>
}