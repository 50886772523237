import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {CountPaginationOffset} from "../../Utils/pagination";
import axios from "axios";
import {API_COURSE_BATCH} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {TableActionStyle} from "../../Utils/table";
import SweetAlert from "sweetalert2";

export const UserCertificateDataTable = ({
                                         courseId = 0,
                                         drawerState,
                                         setDrawerState,
                                         refresh = false,
                                         onRefreshFinish,
                                         customColumns,
                                         dataLoadURL = API_COURSE_BATCH + "/",
                                         dataLoadFilter
                                     }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [filter, setFilter] = useState({
        title: "",
        course_id: courseId
    })

    const [columns, setColumns] = useState([
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
        },
        {
            name: 'Batch Name',
            selector: (row) => row.title,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.id),
            sortable: true,
            center: true,
        },
    ])
    const tableAction = (id) => {
        return (
            <div>
                {/*<span>*/}
                {/*    <Btn attrBtn={{*/}
                {/*        onClick: () => handleDelete(id),*/}
                {/*        style: TableActionStyle,*/}
                {/*        className: 'btn btn-danger btn-xs',*/}
                {/*        type: 'button'*/}
                {/*    }}>Delete</Btn>*/}
                {/*</span> &nbsp;&nbsp;*/}
                <span>
                    <Btn attrBtn={{
                        onClick: () => {
                            setDrawerState({
                                ...drawerState,
                                forms: true,
                                editId: id
                            })
                        }, style: TableActionStyle, className: 'btn btn-success btn-xs', type: 'button'
                    }}>Assign Certificate</Btn>
                </span> &nbsp;&nbsp;
                {/*<span>*/}
                {/*    <Btn attrBtn={{*/}
                {/*        onClick: () => {*/}
                {/*            setDrawerState({*/}
                {/*                ...drawerState,*/}
                {/*                assignUserForms: true,*/}
                {/*                editId: id*/}
                {/*            })*/}
                {/*        }, style: TableActionStyle, className: 'btn btn-primary btn-xs', type: 'button'*/}
                {/*    }}>Manage</Btn>*/}
                {/*</span>*/}
            </div>
        )
    }


    useEffect(() => {
        setFilter({
            ...filter,
            course_id: courseId
        })
        loadCourseBatchData(10, 0, {...filter, course_id: courseId})
    }, [courseId])

    useEffect(() => {
        if (refresh === true) {
            loadCourseBatchData(pagination.rowsPerPage, 0, {...filter})
            onRefreshFinish()
        }
    }, [refresh])

    useEffect(() => {
        if (customColumns !== undefined && customColumns !== null) {
            setColumns(customColumns)
        }
    }, [customColumns])

    const loadCourseBatchData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_COURSE_BATCH + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }


    const handleDelete = (id) => {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.delete(API_COURSE_BATCH + "/?id=" + id).then(r => {
                    if (r.status === 200) {
                        return r.data["data"]
                    }
                }).then(json => {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Deletion Completed!',
                        icon: 'success'
                    }).then(() => {
                        loadCourseBatchData(10, 0, {...filter, course_id: courseId})
                    })
                }).catch(() => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Deletion Failed!',
                        icon: 'error'
                    })
                })
            }
        });
    }

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadCourseBatchData(pagination.rowsPerPage, 0, {...filter})
    }
    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }
    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadCourseBatchData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    }
    const onPageChange = page => {
        loadCourseBatchData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    return (
        <>
            <Col md={12}>
                <Form onSubmit={onSearchSubmit}>
                    <Row className="justify-content-start align-items-end">
                        <Col md="auto">
                            <FormGroup className="row">
                                <Label htmlFor="exampleFormControlSelect3">Search</Label>
                                <Col sm="12">
                                    <Input className="form-control" type="text"
                                           placeholder={"Search Here"} onChange={(e) => {
                                        setFilter({
                                            ...filter,
                                            title: e.target.value
                                        })
                                    }}/>
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col md="auto">
                            <Label htmlFor="exampleFormControlSelect3"></Label>
                            <FormGroup className="row">
                                <Btn attrBtn={{
                                    color: "primary",
                                    className: "m-r-15",
                                    type: "submit"
                                }}>Search</Btn>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
            </Col>

            <Col md={12}>
                <DataTable
                    sortServer
                    pagination
                    paginationServer

                    data={data}
                    columns={columns}
                    progressPending={isLoading}
                    onSort={onSort}
                    paginationTotalRows={pagination.totalRows}
                    onChangeRowsPerPage={onPaginationRowsChangePage}
                    onChangePage={onPageChange}
                    striped={true}
                    center={true}
                />
            </Col>
        </>
    )
}