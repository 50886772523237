import {Link, useNavigate} from "react-router-dom";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_CDN_DEFAULT, API_CONTENT_EVENT, API_CONTENT_SERVICE} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {parseDate} from "../../Utils/date";
import {handlerSetFormString} from "../../Utils/forms";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {CKECustomUploadAdapterPlugin} from "../../Components/Plugins/ck5_upload_adapter";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import slugify from "react-slugify";
import {FilePond} from "react-filepond";

export const EventsForms = ({id = 0}) => {
    const initialData = {
        id: 0,
        created_at: new Date().toISOString(),
        posted_date: new Date().toISOString(),
        event_started_date: new Date().toISOString(),
        event_end_date: new Date().toISOString(),
        title: "",
        slug: "",
        event_organizer_phone_number: "",
        event_venue: "",
        event_content: "",
        event_note: "",
        cover_image_id: ""
    }

    const [data, setData] = useState(initialData)
    const [editorData, setEditorData] = useState("")
    const [coverImage, setCoverImage] = useState([])
    const navigate = useNavigate()
    const [reference, setReference] = useState({
        fp_cover_image: useRef(null)
    })

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_CONTENT_EVENT + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    if (json.data.length > 0) {
                        setData(json.data[0])
                        setEditorData(json.data[0].event_content)
                        if (json.data[0].cover_image_id !== "") {
                            setCoverImage([{
                                source: json.data[0].cover_image_id,
                                options: {type: 'local'}
                            }])
                        }
                    }
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])
    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id === 0) {
            reference.fp_cover_image.current.processFile().then((f) => {
                axios.post(API_CONTENT_EVENT + "/", {
                    ...data,
                    event_content: editorData,
                    cover_image_id: f.serverId
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Success!',
                            text: 'Data Saving Completed!',
                            icon: 'success'
                        }).then(() => {
                            navigate(`${process.env.PUBLIC_URL}/manage/event`)
                        })
                    } else {
                        throw r
                    }
                }).catch((err) => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Saving Failed!',
                        icon: 'error'
                    })
                })
            })
        } else {
            reference.fp_cover_image.current.processFile().then((f) => {
                axios.put(API_CONTENT_EVENT + "/", {
                    ...data,
                    event_content: editorData,
                    cover_image_id: f.serverId
                }).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Success!',
                            text: 'Data Saving Completed!',
                            icon: 'success'
                        }).then(() => {
                            navigate(`${process.env.PUBLIC_URL}/manage/event`)
                        })
                    } else {
                        throw r
                    }
                }).catch((err) => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Saving Failed!',
                        icon: 'error'
                    })
                })
            })
        }
    }
    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} Events</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <Row>
                <Col md={4} sm={12}>
                    <FormGroup>
                        <Label>Post on</Label>
                        <DatePicker className="form-control digits" selected={parseDate(data.posted_date)}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            posted_date: e.toISOString()
                                        })
                                    }}/>
                    </FormGroup>
                </Col>

                <Col md={4} sm={12}>
                    <FormGroup>
                        <Label>Event Start Date</Label>
                        <DatePicker className="form-control digits" selected={parseDate(data.event_started_date)}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            event_started_date: e.toISOString()
                                        })
                                    }}/>
                    </FormGroup>
                </Col>

                <Col md={4} sm={12}>
                    <FormGroup>
                        <Label>Event End Date</Label>
                        <DatePicker className="form-control digits" selected={parseDate(data.event_end_date)}
                                    onChange={(e) => {
                                        setData({
                                            ...data,
                                            event_end_date: e.toISOString()
                                        })
                                    }}/>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup>
                <Label>Event Title</Label>
                <Input required name="title" value={data.title}
                       onChange={(e) => {
                           // handlerSetFormString(e, data, setData)
                           setData({
                               ...data,
                               title: e.target.value,
                               slug: slugify(e.target.value)
                           })
                       }}
                       className="form-control btn-square" type="text"
                       placeholder="Type Something"/>
            </FormGroup>
            <FormGroup>
                <Label>Cover Image</Label>
                <FilePond
                    required
                    files={coverImage}
                    allowMultiple={false}
                    ref={reference.fp_cover_image}
                    // onremovefile={(err, file) => handleRemoveFiles(err, file, "photo_files_id")}
                    // onprocessfile={(err, file) => handleFinishProcess(err, file, "photo_files_id")}
                    onupdatefiles={(newFile) => {
                        setCoverImage(newFile)
                    }}

                    instantUpload={false}
                    maxFiles={1}
                    imageCropAspectRatio={'1620:1080'}
                    acceptedFileTypes={['image/*']}

                    allowImageCrop={true}
                    allowImageTransform={true}
                    allowFileSizeValidation={true}

                    maxFileSize={'2MB'}
                    server={API_CDN_DEFAULT}
                    name="files"
                    labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                />
            </FormGroup>
            <FormGroup>
                <Label>URL Slug</Label>
                <Input required name="slug" value={data.slug}
                       disabled={true}
                       className="form-control btn-square" type="text"
                       placeholder="This will be URL Unique Identifier"/>
            </FormGroup>
            <FormGroup>
                <Label>Organizer Phone Number</Label>
                <Input required name="event_organizer_phone_number" value={data.event_organizer_phone_number}
                       onChange={(e) => handlerSetFormString(e, data, setData)}
                       className="form-control btn-square" type="text"
                       placeholder="Start with 62 or other country code"/>
            </FormGroup>
            <FormGroup>
                <Label>Venue</Label>
                <Input required name="event_venue" value={data.event_venue}
                       onChange={(e) => handlerSetFormString(e, data, setData)}
                       className="form-control btn-square" type="textarea"
                       placeholder="Type Something"/>
            </FormGroup>
            <FormGroup>
                <Label>Note</Label>
                <Input required name="event_note" value={data.event_note}
                       onChange={(e) => handlerSetFormString(e, data, setData)}
                       className="form-control btn-square" type="textarea"
                       placeholder="Type Something"/>
            </FormGroup>

            <FormGroup>
                <Label>Content</Label>
                <CKEditor
                    editor={ClassicEditor}
                    data={editorData}
                    config={{
                        extraPlugins: [CKECustomUploadAdapterPlugin],
                        toolbar: {
                            items: [
                                'heading',
                                '|',
                                'bold',
                                'italic',
                                'link',
                                'bulletedList',
                                'numberedList',
                                '|',
                                'alignment',
                                'outdent',
                                'indent',
                                'uploadImage',
                                'blockQuote',
                                'mediaEmbed',
                                'undo',
                                'redo'
                            ]
                        },
                        mediaEmbed: {
                            previewsInData: true
                        }
                    }}
                    onChange={(event, editor) => {
                        setEditorData(editor.getData())
                    }}
                />
            </FormGroup>
            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                type: 'submit',
            }}
            >Submit</Btn>
            <Link to={`${process.env.PUBLIC_URL}/manage/event`}>
                <Btn attrBtn={{color: 'light'}}>Cancel</Btn>
            </Link>
        </Form>
    </>
}