import DataTable from "react-data-table-component";
import {useState} from "react";
import {tableData} from "../../Data/DummyTableData";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";

export const ProjectDataTable = () => {

    const [data, setData] = useState(tableData);
    const [isLoading, setIsLoading] = useState(false)

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
        },
        {
            name: 'Event Name',
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
    ]


    const onSearchSubmit = (e) => {
        e.preventDefault()
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const [tablePagination, setTablePagination] = useState({
        totalRows: 999,
        rowPerPage: 10
    })

    const onPageChange = page => {
        console.log(page)
    };


    const onPaginationRowsChangePage = (newPerPage, page) => {
        setIsLoading(true);

        console.log(newPerPage, page)

        // const response = await axios.get(`https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`);
        //
        // setData(response.data.data);
        // setPerPage(newPerPage);
        setIsLoading(false);
    }

    return <>
        <Col md={12}>
            <Form onSubmit={onSearchSubmit}>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Search</Label>
                            <Col sm="12">
                                <Input className="form-control" type="text" placeholder={"Search Here"}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{color: "primary", className: "m-r-15", type: "submit"}}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>

        <Col md={12}>
            <DataTable
                data={data}
                columns={columns}
                onSort={onSort}
                sortServer
                pagination
                paginationServer
                paginationTotalRows={tablePagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>
    </>
}