import DataTable from "react-data-table-component";
import React, {useEffect, useRef, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H5, Spinner} from "../../AbstractElements";
import axios from "axios";
import {API_COURSE_EXAM, API_COURSE_EXAM_REPORT, API_COURSE_EXAM_SUBMISSION} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {CountPaginationOffset} from "../../Utils/pagination";
import {CourseExamReportDataTableExpandable} from "./CourseExamReportDataTableExpandable";
import { CSVLink } from "react-csv";
import {Hidden} from "../../Components/Common/Data/Hidden";

export const UserExamReportDataTable = ({
                                            batchID = 0,
                                            drawerState,
                                            setDrawerState,
                                            refresh = false,
                                            onRefreshFinish
                                        }) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })

    useEffect(() => {
        setFilter({
            ...filter,
            batch_id: batchID
        })

        loadData(10, 0, {...filter, batch_id: batchID})
    }, [batchID])

    const [filter, setFilter] = useState({
        title: "",
        batch_id: batchID
    })

    useEffect(() => {
        if (refresh === true) {
            loadData()
            onRefreshFinish()
        }
    }, [refresh])


    const [csvData, setCSVData] = useState( {
        ready : false,
        data : [[]]
    })

    useEffect(() => {
        if (data.length > 0) {
            constructCSVData()
        }
    }, [data])

    const constructCSVData = () => {
        setCSVData({
            ...csvData,
            ready: false
        })

        let result = []
        let userMap = {}

        data.map((v, i) => {
            v.participant_grades.map((vv) => {
                if (userMap[vv.user_full_name] === undefined || userMap[vv.user_full_name] === null) {
                    userMap[vv.user_full_name] = []
                } else {
                    userMap[vv.user_full_name] = [...userMap[vv.user_full_name], vv.user_total_point]
                }
            })
        })

        data.map((v) => {
            let moduleTitle = v.module_title

            let current = [moduleTitle]

            Object.keys(userMap).forEach((key) => {
                let userGrade = v.participant_grades.find((e) => e.user_full_name === key)
                current = [...current, `${userGrade === undefined ? -1 : userGrade.user_total_point}`]
            })

            result = [...result, [...current]]
        })

        result = [['Modules', ...Object.keys(userMap)], ...result]

        setCSVData({
            ready: true,
            data: result
        })

    }

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.module_id,
            sortable: true,
            center: true,
        },
        {
            name: 'Title',
            selector: (row) => `${row.module_title}`,
            sortable: true,
            center: true,
        },
        {
            name: '# of Data',
            selector: (row) => `${row.participant_grades.length}`,
            sortable: true,
            center: true,
        },
    ]

    const loadData = () => {
        setIsLoading(true);
        axios.get(API_COURSE_EXAM_REPORT + `/${batchID}`).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const csvRef = useRef()

    const onSearchSubmit = (e) => {
        e.preventDefault()
        // loadData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        // loadData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        // loadData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    }


    return <>
        <Container fluid={true} className="datatables">
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="pb-0">
                            <Row className="justify-content-between">
                                <Col md={"auto"}>
                                    <H5>Manage Course Batch</H5>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Col md={12}>
                                <Form onSubmit={onSearchSubmit}>
                                    <Row className="justify-content-end align-items-end">
                                        {/*<Col md={10}>*/}
                                        {/*    <Row className="justify-content-start align-items-end">*/}

                                        {/*    <Col md="auto">*/}
                                        {/*        <FormGroup className="row">*/}
                                        {/*            <Label htmlFor="exampleFormControlSelect3">Search</Label>*/}
                                        {/*            <Col sm="12">*/}
                                        {/*                <Input className="form-control" type="text"*/}
                                        {/*                       placeholder={"Search Here"}/>*/}
                                        {/*            </Col>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*    <Col md="auto">*/}
                                        {/*        <Label htmlFor="exampleFormControlSelect3"></Label>*/}
                                        {/*        <FormGroup className="row">*/}
                                        {/*            <Btn attrBtn={{*/}
                                        {/*                color: "primary",*/}
                                        {/*                className: "m-r-15",*/}
                                        {/*                type: "submit"*/}
                                        {/*            }}>Search</Btn>*/}
                                        {/*        </FormGroup>*/}
                                        {/*    </Col>*/}
                                        {/*    </Row>*/}
                                        {/*</Col>*/}
                                        <Col md={3}>
                                            <Label htmlFor="exampleFormControlSelect3"></Label>
                                            <FormGroup className="row">





                                                <Btn attrBtn={{
                                                    color: "primary",
                                                    className: "m-r-15",
                                                    type: "button",
                                                    disabled: !csvData.ready,
                                                    // onClick : () => constructCSVData()
                                                    onClick : () => {
                                                        csvRef.current.link.click()
                                                    }
                                                }}>
                                                    <Row className="justify-content-center align-items-center">
                                                        {!csvData.ready &&
                                                            <Col md="auto">
                                                                <div className="loader-box">
                                                                    <Spinner
                                                                        attrSpinner={{className: "loader-2__mini"}}/>
                                                                </div>
                                                            </Col>
                                                        }
                                                        <Col md="auto">
                                                            Download CSV
                                                        </Col>
                                                    </Row>



                                                </Btn>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>


                            <Col md={12}>
                                <DataTable
                                    pagination
                                    expandableRows

                                    data={data}
                                    columns={columns}
                                    progressPending={isLoading}

                                    // onSort={onSort}
                                    paginationTotalRows={pagination.totalRows}
                                    onChangeRowsPerPage={onPaginationRowsChangePage}
                                    onChangePage={onPageChange}

                                    expandableRowsComponent={CourseExamReportDataTableExpandable}
                                    striped={true}
                                    center={true}
                                />
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>

        <Hidden>
            <CSVLink data={csvData.data} separator={";"} ref={csvRef} filename={`report-batch_id_${batchID}.csv`}>
                CSV
            </CSVLink>
        </Hidden>
    </>
}