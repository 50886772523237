import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";

import React, {useState} from "react";
import {SidenavManageSearchCourse} from "../../Components/Nav/SidenavManageUserCertificate";
import Drawer from "react-modern-drawer";
import {CourseModulesGroupForms} from "./CourseModulesGroupForms";
import {CourseModulesManageGroupForms} from "./CourseModulesManageGroupForms";
import {CourseModuleGroupsDataTable} from "../../Components/DataTables/CourseModuleGroupsDataTable";

const ManageCourseModuleChapterPage = () => {
    const [Sidebar, setSidebar] = useState(false);
    const OnClickHandel = () => {
        setSidebar(!Sidebar);
    };

    const [drawerState, setDrawerState] = useState({
        forms: false,
        manageForm: false,
        editId: 0
    })

    const closeAllDrawer = () => {
        setDrawerState({
            forms: false,
            manageForm: false,
            editId: 0
        })
    }

    const [refreshState, setRefreshState] = useState({
        courseModuleGroupDataTable: false,
        user_typeahead: false,
        participant_datatable: false,
        exam_typeahead: false
    })

    const [selectedCourseID, setSelectedCourseID] = useState(0)

    const handleCourseChange = (courseID) => {
        setSelectedCourseID(parseInt(courseID))
    }

    return (
        <>
            <Breadcrumbs mainTitle="Manage Course Modules" parent="Course" title="Manage Course Modules"/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-6 xl-30">
                            <div className="email-sidebar">
                                <a className="btn btn-primary email-sidebar-toggle" href="#" onClick={OnClickHandel}>Course
                                    Title</a>
                                <div className={`email-left-aside ${Sidebar ? 'open' : ''}`}>
                                    <SidenavManageSearchCourse onChange={handleCourseChange}/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-12 xl-70">
                            {
                                selectedCourseID === 0 ?
                                    <h3>Select Course First</h3>
                                    :                             <div className="email-right-aside bookmark-tabcontent">
                                    <Container fluid={true} className="datatables">
                                        <Row>
                                            <Col sm="12">
                                                <Card>
                                                    <CardHeader className="pb-0">
                                                        <Row className="justify-content-between">
                                                            <Col md={"auto"}>
                                                                <H5>Manage Course Modules</H5>
                                                            </Col>
                                                            <Col md={"auto"}>
                                                                <Btn attrBtn={{
                                                                    color: "secondary",
                                                                    className: "m-r-15",
                                                                    onClick: (e) => setDrawerState({
                                                                        ...drawerState,
                                                                        forms: true,
                                                                        editId: 0
                                                                    })
                                                                }}>New Modules</Btn>
                                                            </Col>
                                                        </Row>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <CourseModuleGroupsDataTable drawerState={drawerState}
                                                                                     setDrawerState={setDrawerState}
                                                                                     courseId={selectedCourseID}
                                                                                     refresh={refreshState.courseModuleGroupDataTable}
                                                                                     onRefreshFinish={() => setRefreshState({
                                                                                         ...refreshState,
                                                                                         courseModuleGroupDataTable: false
                                                                                     })}
                                                        />
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>

                            }
                        </Col>
                    </Row>
                </div>
            </Container>
            <Drawer
                open={drawerState.forms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CourseModulesGroupForms id={drawerState.editId} drawerState={drawerState}
                                         setDrawerState={setDrawerState} courseID={selectedCourseID}
                                         refreshState={refreshState}
                                         setRefreshState={setRefreshState}/>
            </Drawer>
            <Drawer
                open={drawerState.manageForm}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CourseModulesManageGroupForms id={drawerState.editId} courseID={selectedCourseID}
                                               refresher={refreshState}
                                               setRefresher={setRefreshState}/>
            </Drawer>
        </>
    )

}

export default ManageCourseModuleChapterPage