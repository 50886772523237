import {Col, Container, Row} from "reactstrap";
import {Breadcrumbs} from "../../AbstractElements";
import MyProfileEdit from "../../Components/Application/Users/UsersEdit/MyProfile";
import EditMyProfile from "../../Components/Application/Users/UsersEdit/EditmyProfile";
import {useEffect, useState} from "react";
import axios from "axios";
import {API_CONTENT_CONFIGURATOR, API_USER_CURRENT} from "../../api/service";
import EditStatistic from "./EditStatistic";
import {CreateFilterEncoding} from "../../Utils/Base64/request";

const AccountPage = () => {
    const initialStatisticData = {
        value : 0,
        title : ""
    }

    const [statisticData, setStatisticData] = useState([initialStatisticData, initialStatisticData, initialStatisticData, initialStatisticData])

    useEffect(() => {
        axios.get(API_CONTENT_CONFIGURATOR + "/?filter=" + CreateFilterEncoding({
            key : "CONF_CONTENT_STATISTIC"
        }), {withCredentials: true}).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }).then((json) => {
            json.data && json.data.data.length > 0 && setStatisticData([...JSON.parse(json.data.data[0].value)])
        })
    }, [])

    return (
        <>
            <Breadcrumbs mainTitle="User Edit" parent="Users" title="User Edit"/>
            <Container fluid={true}>
                <div className="edit-profile">
                    <Row>
                        <Col xl="12">
                            <EditStatistic statisticData={statisticData} setStatisticData={setStatisticData}/>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    )
}

export default AccountPage