import Select from "react-select";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_COURSE_EXAM} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";


export const ExamTypeahead = ({
                                  courseID = 0,
                                  onChange,
                                  refresh = false,
                                  defaultID = 0,
                                  onRefreshFinish,
                                  isMulti = false
                              }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [selected, setSelected] = useState([])

    const [cid, setCID] = useState(courseID)

    useEffect(() => {
        if (refresh === true) {
            setSelected([])
            onRefreshFinish()
        }
    }, [refresh])

    useEffect(() => {
        if (defaultID !== 0) {
            setIsLoading(true);
            axios.get(API_COURSE_EXAM + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: defaultID
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    setSelected(json.data.length > 0 ? json.data[0] : [])
                } else {
                    setSelected([])
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }, [defaultID])


    useEffect(() => {
        setCID(courseID)
        loadUserData(10, 0, {
            course_id: cid
        })
    }, [courseID])

    const loadUserData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_COURSE_EXAM + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setOptions(json.data.length > 0 ? json.data : [])
            } else {
                setOptions([])
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const handleInputChange = (e, m) => {
        loadUserData(10, 0, {
            title: e,
            course_id: cid
        })
    }

    useEffect(() => {
        loadUserData(10, 0, {
            course_id: cid
        })
    }, [])

    const handleOnChange = (newValue) => {
        setSelected(newValue)
        onChange(newValue)
    }

    return (
        <>
            <Select
                getOptionLabel={(opt) => `${opt.title}`}
                value={selected}
                getOptionValue={(opt) => `${opt.title}`}
                onChange={(newValue, m) => {
                    handleOnChange(newValue)
                }}
                onInputChange={handleInputChange}
                options={options}
                loadOption={isLoading}
                className="js-example-basic-single col-sm-12"
                isMulti={isMulti}/>
        </>
    )
}
