export const handlerSetFormString = (e, state, stateSetter) => {
    stateSetter({
        ...state,
        [e.target.name]: e.target.value
    })
}

export const handlerSetFormNumeric = (e, state, stateSetter) => {
    stateSetter({
        ...state,
        [e.target.name]: parseInt(e.target.value)
    })
}

