import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {ButtonGroup, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import axios from "axios";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_USER_ABSENCE} from "../../api/service";
import {CountPaginationOffset} from "../../Utils/pagination";

export const ParticipantDataTable = ({classID = 0, refresh = false, onRefreshFinish}) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [isLoading, setIsLoading] = useState(false)
    const [isSearching, setIsSearching] = useState(false)
    const [filter, setFilter] = useState({
        class_id: 0,
        full_name: "",
    })

    useEffect(() => {
        if (classID !== 0) {
            setFilter({
                ...filter,
                class_id: classID,
                full_name: ""
            })
            setIsSearching(false)
            loadData(10, 0, {
                ...filter,
                class_id: classID,
                full_name: ""
            })
        }
    }, [classID])

    useEffect(() => {
        if (refresh === true) {
            loadData(pagination.rowsPerPage, 0, {...filter})
            onRefreshFinish()
        }
    }, [refresh])

    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Name',
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            name: 'Absence',
            selector: (row) => tableAction(row),
            sortable: true,
            center: true,
        },
    ]

    const tableAction = (row) => {
        return (<div>
                    <span>
                        <ButtonGroup className={"primary"}>
                                            <Btn attrBtn={{
                                                color: "success",
                                                outline: row.status !== 1,
                                                onClick: () => {
                                                    let newData = data
                                                    newData[data.indexOf((row))].status = 1
                                                    setData([...newData])
                                                    updateAbsenceData({
                                                        ...row,
                                                        status : 1
                                                    })
                                                }
                                            }}>P</Btn>
                                            <Btn attrBtn={{
                                                color: "primary",
                                                outline: row.status !== 2,
                                                onClick: () => {
                                                    let newData = data
                                                    newData[data.indexOf((row))].status = 2
                                                    setData([...newData])
                                                    updateAbsenceData({
                                                        ...row,
                                                        status : 2
                                                    })
                                                }
                                            }}>A</Btn>
                                        </ButtonGroup>
                    </span>
        </div>)
    }

    const loadData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_USER_ABSENCE + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const updateAbsenceData = (rowData) => {
        axios.put(API_USER_ABSENCE + "/", {
            class_id: rowData.class_id,
            full_name: rowData.full_name,
            id: rowData.id,
            status: rowData.status,
            user_id: rowData.user_id,
        }, {withCredentials: true})
            .then(() => {

            })
    }

    const onGenerateAbsence = () => {
        if (classID !== 0) {
            setIsLoading(true)
            axios.get(API_USER_ABSENCE + `/generate/${classID}`).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    setData(json.data.length > 0 ? json.data : [])
                    setPagination({
                        ...pagination, totalRows: json.count.all
                    })
                } else {
                    setData([])
                    setPagination({
                        ...pagination, totalRows: 0
                    })
                }
            }).finally(() => {
                setIsLoading(false);
            })
        }
    }

    const doSearch = (e) => {
        e.preventDefault()
        setIsSearching(true)
        loadData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page, rowsPerPage: newPerPage
        })
    }

    return <>
        {!isSearching && data <= 0 && <Col md={12}>
            <Label style={{
                width: "100%",
                textAlign: "center"
            }}>Absence Not Yet Generated</Label>
            {!isLoading ? <Btn attrBtn={{
                color: "primary", className: "w-100", onClick: () => {
                    onGenerateAbsence()
                }
            }}>Generate Absence</Btn> : <Label style={{
                width: "100%",
                textAlign: "center"
            }}>Generating ....</Label>}
        </Col>}


        {(isSearching || data.length > 0) && <Col md={12}>
            <Form>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Search</Label>
                            <Col sm="12">
                                <Input className="form-control" type="text" placeholder={"Search Here"}
                                       onChange={(e) => {
                                           setFilter({
                                               ...filter,
                                               full_name: e.target.value,
                                           })
                                       }}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{color: "primary", className: "m-r-15", type: "button", onClick : doSearch}}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>}

        {(isSearching || data.length > 0) && <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>
        }

    </>
}