import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import React, {useState} from "react";
import {SidenavManageSearchCourse} from "../../Components/Nav/SidenavManageUserCertificate";
import Drawer from "react-modern-drawer";
import {CourseExamForms} from "./CourseExamForms";
import {CourseExamQuestionForms} from "./CourseExamQuestionForms";
import {CourseExamDataTable} from "../../Components/DataTables/CourseExamDataTable";

const ManageCourseExamPage = () => {
    const [Sidebar, setSidebar] = useState(false);
    const OnClickHandel = () => {
        setSidebar(!Sidebar);
    };

    const [drawerState, setDrawerState] = useState({
        forms: false,
        questionForms: false,
        editId: 0
    })

    const closeAllDrawer = () => {
        setDrawerState({
            forms: false,
            questionForms: false,
            editId: 0
        })
    }

    const [selectedCourseID, setSelectedCourseID] = useState(0)

    const handleCourseChange = (courseID) => {
        setSelectedCourseID(parseInt(courseID))
    }

    const [refresher, setRefresher] = useState({
        courseExamDataTable: false
    })

    return (
        <>
            <Breadcrumbs mainTitle="Manage Course Exam" parent="Course" title="Manage Course Exam"/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-6 xl-30">
                            <div className="email-sidebar">
                                <a className="btn btn-primary email-sidebar-toggle" href="#" onClick={OnClickHandel}>Course
                                    Title</a>
                                <div className={`email-left-aside ${Sidebar ? 'open' : ''}`}>
                                    <SidenavManageSearchCourse onChange={handleCourseChange}
                                                               setSelectedCourseID={setSelectedCourseID}/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-12 xl-70">
                            {
                                selectedCourseID === 0 ?
                                    <h3>Select Course First</h3>
                                    :
                                    <div className="email-right-aside bookmark-tabcontent">
                                        <Container fluid={true} className="datatables">
                                            <Row>
                                                <Col sm="12">
                                                    <Card>
                                                        <CardHeader className="pb-0">
                                                            <Row className="justify-content-between">
                                                                <Col md={"auto"}>
                                                                    <H5>Manage Course Exam</H5>
                                                                </Col>
                                                                <Col md={"auto"}>
                                                                    <Btn attrBtn={{
                                                                        color: "secondary",
                                                                        className: "m-r-15",
                                                                        onClick: (e) => setDrawerState({
                                                                            ...drawerState,
                                                                            forms: true,
                                                                            editId: 0
                                                                        })
                                                                    }}>New Exam</Btn>
                                                                </Col>
                                                            </Row>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <CourseExamDataTable drawerState={drawerState}
                                                                                 setDrawerState={setDrawerState}
                                                                                 courseId={selectedCourseID}
                                                                                 refresh={refresher.courseExamDataTable}
                                                                                 onRefreshFinish={() => setRefresher({
                                                                                     ...refresher,
                                                                                     courseExamDataTable: false
                                                                                 })}/>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                            }
                        </Col>
                    </Row>
                </div>
            </Container>
            <Drawer
                open={drawerState.forms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CourseExamForms id={drawerState.editId} courseID={selectedCourseID} refresher={refresher}
                                 setRefresher={setRefresher} drawerState={drawerState}
                                 setDrawerState={setDrawerState}/>
            </Drawer>
            <Drawer
                open={drawerState.questionForms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <CourseExamQuestionForms id={drawerState.editId} courseID={selectedCourseID} refresher={refresher}
                                         setRefresher={setRefresher} drawerState={drawerState}
                                         setDrawerState={setDrawerState}/>
            </Drawer>
        </>
    )

}

export default ManageCourseExamPage