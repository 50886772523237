import {Col, Form, Row} from "reactstrap";
import {H4} from "../../AbstractElements";
import React, {useEffect, useState} from "react";
import {UserExamReportDataTable} from "./UserExamReportDataTable";

export const UserExamReportDrawer = ({id = 0, courseID = 0}) => {

    // const navigate = useNavigate()

    const [currentBatchID, setCurrentBatchID] = useState(id)

    useEffect(() => {
        setCurrentBatchID(id)
    }, [id])

    return <>
        <div className="m-b-30">
            <H4>Course Exam Report</H4>
            <hr/>
        </div>
        <Form className="form theme-form">
            <Row>
                <Col>
                    <UserExamReportDataTable batchID={currentBatchID}/>
                </Col>
            </Row>
        </Form>
    </>
}