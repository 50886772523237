import {CourseClassForms} from "./CourseClassForms";
import {CourseBatchClassDataTable} from "../../../Components/DataTables/CourseBatchClassDataTable";
import React from "react";

export const BatchClassAssignTab = ({refresher, setRefresher, batchId}) => {
    return <>
        <CourseClassForms batchID={batchId} onFinish={() => {
            setRefresher({
                ...refresher,
                class_datatable: true
            })
        }}/>
        <hr/>
        <CourseBatchClassDataTable batchID={batchId}
                                   refresh={refresher.class_datatable}
                                   onRefreshFinish={() => {
                                       setRefresher({
                                           ...refresher,
                                           class_datatable: false
                                       })
                                   }}/>
    </>

}