import {Breadcrumbs} from "../../AbstractElements";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import HeaderCard from "../../Components/Common/Component/HeaderCard";
import {ProjectDataTable} from "./ProjectDataTable";

const ManageProjectPage = () => {

    return (
        <>
            <Breadcrumbs parent="Content" title="Manage Project" mainTitle="Manage Project" />
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard title="Manage Project" />
                            <CardBody>
                                <ProjectDataTable/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export default ManageProjectPage