import {Breadcrumbs, Btn, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";
import Drawer from "react-modern-drawer";
import React, {useState} from "react";
import {EventDataTable} from "../../Components/DataTables/EventDataTable";
import {ServiceForms} from "./ServiceForms";
import {ServicesDataTable} from "../../Components/DataTables/ServicesDataTable";

const ManageServicePage = () => {
    const [drawerState, setDrawerState] = useState({
        forms: false,
        editId: 0
    })

    const closeAllDrawer = () => {
        setDrawerState({
            forms: false,
            editId: 0
        })
    }

    return (
        <>
            <Breadcrumbs parent="Content" title="Manage Services" mainTitle="Manage Services"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Manage Service</H5>
                                    </Col>
                                    <Col md={"auto"}>
                                        <Btn attrBtn={{
                                            color: "secondary",
                                            className: "m-r-15",
                                            onClick: (e) => setDrawerState({...drawerState, forms: true, editId: 0})
                                        }}>New Service</Btn>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <ServicesDataTable drawerState={drawerState} setDrawerState={setDrawerState}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Drawer
                open={drawerState.forms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <ServiceForms id={drawerState.editId}/>
            </Drawer>
        </>
    )

}

export default ManageServicePage