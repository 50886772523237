import React, {Fragment, useState} from "react";
import {CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import axios from "axios";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {Btn, H4, H6} from "../../AbstractElements";
import {API_CONTENT_CONFIGURATOR, API_COURSE_MODULE_GROUPS} from "../../api/service";

const EditStatistic = ({
                           statisticData,
                           setStatisticData,
                       }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        axios.put(API_CONTENT_CONFIGURATOR + "/", {
            key : "CONF_CONTENT_STATISTIC",
            value : statisticData
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Data Saving Completed!',
                    icon: 'success'
                })
            } else {
                throw r
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Data Saving Failed!',
                icon: 'error'
            })
        })

    }
    return (
        <Fragment>
            <Form className="card" onSubmit={handleSubmit}>
                <CardHeader className="pb-0">
                    <H4 attrH4={{className: "card-title mb-0"}}>Edit Statistics</H4>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        <hr className={"m-t-15"}/>

                        {
                            statisticData.map((v,i) => {
                                return <>
                                    <H6>Statistic {i + 1}</H6>

                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Title</Label>
                                            <Input
                                                value={v.title}
                                                onChange={(e) => {
                                                    let csd = statisticData
                                                    let newData = csd[i]
                                                    newData = {
                                                        ...newData,
                                                        title : e.target.value
                                                    }
                                                    csd[i] = newData
                                                    setStatisticData([...csd])
                                                }}


                                                className="form-control" type="text" required="" placeholder="Title"/>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Count</Label>
                                            <Input
                                                value={v.value}
                                                onChange={(e) => {
                                                    let csd = statisticData
                                                    let newData = csd[i]
                                                    newData = {
                                                        ...newData,
                                                        value : e.target.value
                                                    }
                                                    csd[i] = newData
                                                    setStatisticData([...csd])
                                                }}
                                                className="form-control" type="number" required="" placeholder="Count"/>
                                        </FormGroup>
                                    </Col>

                                    <hr className={"m-t-15"}/>
                                </>
                            })
                        }


                    </Row>
                </CardBody>
                <CardFooter className="text-end">
                    <Btn attrBtn={{color: "primary", type: "submit"}}>Save</Btn>
                </CardFooter>
            </Form>
        </Fragment>
    )
}
export default EditStatistic