import {Col, Form, Row} from "reactstrap";
import {H4} from "../../AbstractElements";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_COURSE_BATCH} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {ParticipantDataTable} from "./ParticipantDataTable";

export const AbsenceForms = ({batchID = 0, classID = 0}) => {
    const [batchData, setBatchData] = useState({
        title: "",
        course_id: 0,
        exam_id: 0,
        module_group_id: 0,
        participant_ids: [],
    })


    useEffect(() => {
        console.log("BID", batchID)
        if (batchID !== 0) {
            axios.get(API_COURSE_BATCH + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: batchID
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setBatchData(json.data[0])
                }
            })
        }
    }, [batchID])

    // const navigate = useNavigate()

    const handlerSubmit = (e) => {
        e.preventDefault()
        // if (id === 0) {
        //     axios.post(API_USER + "/", {...data}).then(r => {
        //         if (r.status === 204) {
        //             SweetAlert.fire({
        //                 title: 'Success!',
        //                 text: 'Data Saving Completed!',
        //                 icon: 'success'
        //             }).then(() => {
        //                 navigate(`${process.env.PUBLIC_URL}/manage/user`)
        //             })
        //         } else {
        //             throw r
        //         }
        //     }).catch((err) => {
        //         SweetAlert.fire({
        //             title: 'Failed!',
        //             text: 'Data Saving Failed!',
        //             icon: 'error'
        //         })
        //     })
        // } else {
        //     axios.put(API_USER + "/aa", {...data}).then(r => {
        //         if (r.status === 204) {
        //             SweetAlert.fire({
        //                 title: 'Success!',
        //                 text: 'Data Saving Completed!',
        //                 icon: 'success'
        //             }).then(() => {
        //                 navigate(`${process.env.PUBLIC_URL}/manage/user`)
        //             })
        //         } else {
        //             throw r
        //         }
        //     }).catch((err) => {
        //         SweetAlert.fire({
        //             title: 'Failed!',
        //             text: 'Data Saving Failed!',
        //             icon: 'error'
        //         })
        //     })
        // }
    }

    return <>
        <div className="m-b-30">
            <H4>Participant Absence</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <Row className={"m-b-15"}>
                <Col>
                    <ParticipantDataTable classID={classID}/>
                </Col>
            </Row>
        </Form>
    </>
}