import {H4} from "../../AbstractElements";
import React, {useEffect, useState} from "react";
import {Row} from "reactstrap";
import {UserDataTable} from "../../Components/DataTables/UserDataTable";
import {FileUploadCertificate} from "./FileUploadCertificate";
import axios from "axios";
import {API_COURSE_BATCH} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";

export const UserCertificateForms = ({id = 0, refreshState, setRefreshState, onFinish}) => {
    const [batchId, setBatchID] = useState(0)

    useEffect(() => {
        setBatchID(id)
    }, [id])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (batchId === 0) {
            // axios.post(API_USER + "/", {...accountData}).then(r => {
            //     if (r.status === 204) {
            //         SweetAlert.fire({
            //             title: 'Success!',
            //             text: 'Data Saving Completed!',
            //             icon: 'success'
            //         }).then(() => {
            //             setRefreshState({
            //                 ...refreshState,
            //                 userDataTable: true
            //             })
            //             onFinish()
            //         })
            //     } else {
            //         throw r
            //     }
            // }).catch((err) => {
            //     SweetAlert.fire({
            //         title: 'Failed!',
            //         text: 'Data Saving Failed!',
            //         icon: 'error'
            //     })
            // })
        } else {
            // axios.put(API_USER + "/aa", {...accountData}).then(r => {
            //     if (r.status === 204) {
            //         SweetAlert.fire({
            //             title: 'Success!',
            //             text: 'Data Saving Completed!',
            //             icon: 'success'
            //         }).then(() => {
            //             setRefreshState({
            //                 ...refreshState,
            //                 userDataTable: true
            //             })
            //             onFinish()
            //         })
            //     } else {
            //         throw r
            //     }
            // }).catch((err) => {
            //     SweetAlert.fire({
            //         title: 'Failed!',
            //         text: 'Data Saving Failed!',
            //         icon: 'error'
            //     })
            // })
        }
    }

    const [userCertificateFiles, setUserCertificateFiles] = useState({
        0: []
    })

    const userTableColumns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Name',
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.id, batchId),
            sortable: true,
            width: "600px",
            center: true,
        },
    ]

    const tableAction = (userID, batchID) => {
        return <FileUploadCertificate batchId={batchID} userId={userID}/>
    }

    useEffect(() => {
        axios.get(API_COURSE_BATCH + "/?pagination=" + CreatePaginationEncoding(10, 0) + "&filter=" + CreateFilterEncoding({
            id: batchId
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            console.log(json.data)
            // if (json.data) {
            //     setData(json.data.length > 0 ? json.data : [])
            //     setPagination({
            //         ...pagination, totalRows: json.count.all
            //     })
            // } else {
            //     setData([])
            //     setPagination({
            //         ...pagination, totalRows: 0
            //     })
            // }
        })
    }, [batchId])

    return (
        <>
            <div className="m-b-30">
                <H4>User Certificate {batchId}</H4>
                <hr/>
            </div>
            {/*<Form className="form theme-form" onSubmit={handlerSubmit}>*/}
            <Row>
                {batchId !== 0 &&
                    <UserDataTable customColumns={userTableColumns} dataLoadURL={API_COURSE_BATCH + "/participant"}
                                   dataLoadFilter={{
                                       id: batchId
                                   }}/>}
            </Row>
            {/*</Form>*/}
        </>
    )
}