import {
    Card,
    CardBody,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import {H4} from "../../../AbstractElements";
import React, {useEffect, useState} from "react";
import {handlerSetFormString} from "../../../Utils/forms";
import axios from "axios";
import {API_COURSE_BATCH} from "../../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import {ParticipantAssignTab} from "./ParticipantAssignTab";
import {BatchClassAssignTab} from "./BatchClassAssignTab";
import {BatchFinalExamAssignTab} from "./BatchFinalExamAssignTab";
import {CourseModulesAssignTab} from "./CourseModulesAssignTab";

export const CourseBatchManageForms = ({id = 0, courseID = 0}) => {
    const initialData = {
        title: "",
        course_id: courseID,
        exam_id: 0,
        modules: [],
        participant_ids: [],
        exam_time: 0,
        exam_due_date: new Date().toISOString(),
        exam_start_date: new Date().toISOString(),
    }

    const [data, setData] = useState(initialData)
    const [newParticipant, setNewParticipant] = useState([])
    // const navigate = useNavigate()

    const loadBatchData = () => [
        axios.get(API_COURSE_BATCH + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
            id: id
        })).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                json.data.length > 0 && setData(json.data[0])
            }
        }).finally(() => {
            setRefresher({...refresher, participant_datatable: true})
        })
    ]

    const updateBatchData = () => {
        axios.put(API_COURSE_BATCH + "/", {
            ...data
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Data Saving Completed!',
                    icon: 'success'
                }).then(() => {
                    loadBatchData()
                }).then(() => {
                    setRefresher({
                        ...refresher,
                        user_typeahead: true,
                    })
                    // navigate(`${process.env.PUBLIC_URL}/manage/course-batch`)
                })
            } else {
                throw r
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Data Saving Failed!',
                icon: 'error'
            })
        })
    }

    useEffect(() => {
        if (id !== 0) {
            loadBatchData()
        } else {
            setData(initialData)
        }
        setRefresher({...refresher, participant_datatable: true})

    }, [id])

    useEffect(() => {
        setData({
            ...initialData,
            course_id: courseID
        })
    }, [courseID])


    const [refresher, setRefresher] = useState({
        user_typeahead: false,
        exam_typeahead: false,
        module_group_typeahead: false,
        participant_datatable: false,
        class_datatable: false,
    })

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id !== 0 && newParticipant.length > 0) {
            let participantIds = []

            newParticipant.map((v, i) => {
                participantIds = [...participantIds, v.id]
            })

            axios.post(API_COURSE_BATCH + "/assign-user", {
                new_participant_ids: participantIds,
                batch_id: id
            }).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        loadBatchData()
                    }).then(() => {
                        setRefresher({
                            ...refresher,
                            user_typeahead: true,
                            module_group_typeahead: true,
                        })
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        }
    }

    const [BasicLineTab, setBasicLineTab] = useState('1');
    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} Course Batch</H4>
            <hr/>
        </div>


        <Row>
            <Col>
                <FormGroup>
                    <Label>Batch Name</Label>
                    <Input required name="title" value={data.title} disabled={true}
                           onChange={(e) => handlerSetFormString(e, data, setData)}
                           className="form-control btn-square" type="text"
                           placeholder="Type Something"/>
                </FormGroup>

                <Card>
                    {/*<CardHeader className="pb-0">*/}
                    {/*    <H5>{SimpleMaterialTab}</H5><span>{'Add'}*/}
                    {/*    <code>{'.border-tab'}</code> {'class with nav class'}</span>*/}
                    {/*</CardHeader>*/}
                    <CardBody>
                        <Nav className="border-tab" tabs>
                            <NavItem>
                                <NavLink href="#javascript" className={BasicLineTab === '1' ? 'active' : ''}
                                         onClick={() => setBasicLineTab('1')}><i
                                    className="icofont icofont-ui-home"></i>Participant</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" className={BasicLineTab === '2' ? 'active' : ''}
                                         onClick={() => setBasicLineTab('2')}><i
                                    className="icofont icofont-man-in-glasses"></i>Classes</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" className={BasicLineTab === '3' ? 'active' : ''}
                                         onClick={() => setBasicLineTab('3')}><i
                                    className="icofont icofont-envelope-open"></i>Modules</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="#javascript" className={BasicLineTab === '4' ? 'active' : ''}
                                         onClick={() => setBasicLineTab('4')}><i
                                    className="icofont icofont-contacts"></i>Final Exam</NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={BasicLineTab}>
                            <TabPane className="fade show" tabId="1">
                                <Form className="form theme-form m-b-30" onSubmit={handlerSubmit}>
                                    <ParticipantAssignTab setNewParticipant={setNewParticipant} refresher={refresher}
                                                          setRefresher={setRefresher} data={data} setData={setData}/>
                                </Form>
                            </TabPane>

                            <TabPane tabId="2">
                                <BatchClassAssignTab refresher={refresher} setRefresher={setRefresher} batchId={id}/>
                            </TabPane>

                            <TabPane tabId="3">
                                <CourseModulesAssignTab data={data} setData={setData} refresher={refresher}
                                                        setRefresher={setRefresher} loadBatchData={loadBatchData}/>
                            </TabPane>

                            <TabPane tabId="4">
                                <BatchFinalExamAssignTab data={data} setData={setData} refresher={refresher}
                                                         setRefresher={setRefresher} loadBatchData={loadBatchData}/>
                            </TabPane>
                        </TabContent>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>
}