import DataTable from "react-data-table-component";
import {useEffect, useState} from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn} from "../../AbstractElements";
import axios from "axios";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {API_CONTENT_TESTIMONIAL, API_USER} from "../../api/service";
import {CountPaginationOffset} from "../../Utils/pagination";
import {TableActionStyle} from "../../Utils/table";
import SweetAlert from "sweetalert2";
import {useNavigate} from 'react-router-dom';

export const TestimonialDataTable = ({
                                  drawerState,
                                  setDrawerState,
                                  refresh = false,
                                  onRefreshFinish,
                                  customColumns,
                                  dataLoadURL = API_CONTENT_TESTIMONIAL + "/",
                                  dataLoadFilter
                              }) => {
    const [data, setData] = useState([]);
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })
    const [isLoading, setIsLoading] = useState(false)
    const [filter, setFilter] = useState({
        email: "",
        full_name: "",
        connector: "or"
    })
    const navigate = useNavigate()

    const [columns, setColumns] = useState([
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.id),
            sortable: true,
            center: true,
        },
    ]);

    const tableAction = (id) => {
        return (<div>
                    <span>
                        <Btn attrBtn={{
                            onClick: () => handleDelete(id),
                            style: TableActionStyle,
                            className: 'btn btn-danger btn-xs',
                            type: 'button'
                        }}>Delete</Btn>
                    </span> &nbsp;&nbsp;
            <span>
                        <Btn attrBtn={{
                            onClick: () => {
                                setDrawerState({
                                    ...drawerState,
                                    forms: true,
                                    editId: id
                                })
                            }, style: TableActionStyle, className: 'btn btn-success btn-xs', type: 'button'
                        }}>Edit </Btn>
                    </span>
        </div>)
    }

    const handleDelete = (id) => {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {
                axios.delete(API_CONTENT_TESTIMONIAL + "/?id=" + id).then(r => {
                    if (r.status === 200) {
                        return r.data["data"]
                    }
                }).then(json => {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Deletion Completed!',
                        icon: 'success'
                    }).then(() => {
                        loadData()
                    })
                }).catch(() => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Deletion Failed!',
                        icon: 'error'
                    })
                })
            }
        });


    }

    const loadData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);

        let _filters = {
            ...filter
        }

        if (dataLoadFilter !== undefined && dataLoadFilter !== null) {
            _filters = {
                ...filter,
                ...dataLoadFilter
            }
        }

        axios.get(dataLoadURL + "?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(_filters)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        if (refresh === true) {
            loadData(pagination.rowsPerPage, CountPaginationOffset(pagination.page, pagination.rowsPerPage), {
                ...filter
            })
            onRefreshFinish()
        }
    }, [refresh])

    useEffect(() => {
        if (customColumns !== undefined && customColumns !== null) {
            setColumns(customColumns)
        }
    }, [customColumns])

    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };

    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadData(newPerPage, CountPaginationOffset(page, newPerPage))
        setPagination({
            ...pagination, page: page, rowsPerPage: newPerPage
        })
    }

    return <>
        <Col md={12}>
            <Form>
                <Row className="justify-content-start align-items-end">
                    <Col md="auto">
                        <FormGroup className="row">
                            <Label htmlFor="exampleFormControlSelect3">Search</Label>
                            <Col sm="12">
                                <Input className="form-control" type="text" placeholder={"Search Here"}
                                       onChange={(e) => {
                                           setFilter({
                                               ...filter,
                                               full_name: e.target.value,
                                               email: e.target.value
                                           })
                                       }}/>
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md="auto">
                        <Label htmlFor="exampleFormControlSelect3"></Label>
                        <FormGroup className="row">
                            <Btn attrBtn={{
                                color: "primary",
                                className: "m-r-15",
                                type: "button",
                                onClick: onSearchSubmit
                            }}>Search</Btn>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        </Col>

        <Col md={12}>
            <DataTable
                sortServer
                pagination
                paginationServer

                data={data}
                columns={columns}
                progressPending={isLoading}
                onSort={onSort}
                paginationTotalRows={pagination.totalRows}
                onChangeRowsPerPage={onPaginationRowsChangePage}
                onChangePage={onPageChange}
                striped={true}
                center={true}
            />
        </Col>

    </>
}