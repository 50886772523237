import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_CDN_DEFAULT, API_USER} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {parseDate} from "../../Utils/date";
import Select from "react-select";

export const UserLookupForms = ({id = 0, refreshState, setRefreshState, onFinish}) => {
    const initialData = {
        id: 0,
        email: "",
        password: "",
        role: 1,
        full_name: "",
        gender: 0,
        birth_date: "0001-01-01T00:00:00Z",
        address: "",
        company_name: "",
        last_education: "",
        education_major: "",
        university: "",
        phone_number: "",
        whatsapp_number: "",

        cv_files_id: "",
        last_education_certificate_files_id: "",
        photo_files_id: ""
    }
    const roleOptions = [
        {value: 1, label: 'User'},
        {value: 2, label: 'Admin'},
    ]
    const genderOptions = [
        {value: 1, label: 'Male'},
        {value: 2, label: 'Female'},
    ];

    const [accountData, setAccountData] = useState(initialData)

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_USER + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setAccountData(json.data[0])
                }
            })
        } else {
            setAccountData(initialData)
        }
    }, [id])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id === 0) {
            axios.post(API_USER + "/", {...accountData}, {withCredentials: true}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        setRefreshState({
                            ...refreshState,
                            userDataTable: true
                        })
                        onFinish()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        } else {
            axios.put(API_USER + "/aa", {...accountData}, {withCredentials: true}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        setRefreshState({
                            ...refreshState,
                            userDataTable: true
                        })
                        onFinish()
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        }
    }

    const handleBirthDateChange = date => {
        setAccountData({
            ...accountData,
            birth_date: date.toISOString()
        })
    };

    return <>
        <div className="m-b-30">
            <H4>Lookup User</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <Row>
                <Col md={12}>
                    <FormGroup className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input className="form-control" placeholder="Email"
                               disabled
                               value={accountData.email}
                               onChange={(e) => setAccountData({...accountData, email: e.target.value})}
                               type={"email"}
                        />
                    </FormGroup>
                </Col>
                <hr/>
                <Col md={12}>
                    <FormGroup>
                        <Label>Full Name</Label>
                        <Input
                            disabled
                            value={accountData.full_name}
                            onChange={(e) => setAccountData({...accountData, full_name: e.target.value})}
                            className="form-control" type="text" required="" placeholder="Full Name"/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Birth Date</Label>
                        <DatePicker disabled value={parseDate(accountData.birth_date)} className="form-control digits"
                                    selected={parseDate(accountData.birth_date)}
                                    onChange={handleBirthDateChange}/>
                    </FormGroup>
                </Col>
                <Col md={6}>
                    <FormGroup>
                        <Label>Gender</Label>
                        <Select
                            options={genderOptions} disabled
                            value={genderOptions.find((e) => e.value === accountData.gender)}
                            className="js-example-basic-single col-sm-12"
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup>
                        <Label>Address</Label>
                        <Input
                            disabled
                            value={accountData.address}
                            onChange={(e) => setAccountData({...accountData, address: e.target.value})}
                            className="form-control" type="textarea" required="" placeholder="Address"/>
                    </FormGroup>
                </Col>
                <hr/>
                <Col md={6}>
                    <FormGroup>
                        <Label>Last Education</Label>
                        <Input
                            disabled
                            value={accountData.last_education}
                            onChange={(e) => setAccountData({...accountData, last_education: e.target.value})}
                            className="form-control" type="text" required="" placeholder="Last Education"/>
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label>Education Major</Label>
                        <Input
                            disabled
                            value={accountData.education_major}
                            onChange={(e) => setAccountData({...accountData, education_major: e.target.value})}
                            className="form-control" type="text" required="" placeholder="Education Major"/>
                    </FormGroup>
                </Col>

                <Col md={12}>
                    <FormGroup>
                        <Label>Company Name</Label>
                        <Input
                            disabled
                            value={accountData.company_name}
                            onChange={(e) => setAccountData({...accountData, company_name: e.target.value})}
                            className="form-control" type="text" required="" placeholder="Company Name"/>
                    </FormGroup>

                    <FormGroup>
                        <Label>University</Label>
                        <Input
                            disabled
                            value={accountData.university}
                            onChange={(e) => setAccountData({...accountData, university: e.target.value})}
                            className="form-control" type="text" required="" placeholder="University"/>
                    </FormGroup>
                </Col>
                <hr/>

                <Col md={6}>
                    <FormGroup>
                        <Label>Phone Number</Label>
                        <div className="input-group">
                            <span className="input-group-text">+62</span>
                            <Input
                                disabled
                                value={accountData.phone_number}
                                onChange={(e) => setAccountData({...accountData, phone_number: e.target.value})}
                                className="form-control" type="text" required="" placeholder="Phone Number"/>
                        </div>
                    </FormGroup>
                </Col>

                <Col md={6}>
                    <FormGroup>
                        <Label>Whatsapp Number</Label>
                        <div className="input-group">
                            <span className="input-group-text">+62</span>
                            <Input
                                disabled
                                value={accountData.whatsapp_number}
                                onChange={(e) => setAccountData({
                                    ...accountData,
                                    whatsapp_number: e.target.value
                                })}
                                className="form-control" type="text" required="" placeholder="Whatsapp Number"/>
                        </div>
                    </FormGroup>
                </Col>
                <hr/>
                <Col md={12}>
                    <FormGroup>
                        <Label>Role</Label>
                        <Select
                            options={roleOptions}
                            value={roleOptions.find((e) => e.value === accountData.role)}
                            className="js-example-basic-single col-sm-12"
                        />
                    </FormGroup>
                </Col>
                <hr/>
                <Row>
                    <Col md={4}>
                        <Btn attrBtn={{color: 'primary', className: "mb-2 w-100", href: `${API_CDN_DEFAULT}/download?load=${accountData.photo_files_id}`, target: "_blank"}}>Download Photo</Btn>
                    </Col>
                    <Col md={4}>
                        <Btn attrBtn={{color: 'primary', className: "mb-2 w-100", href: `${API_CDN_DEFAULT}/download?load=${accountData.cv_files_id}`, target: "_blank"}}>Download CV</Btn>
                    </Col>
                    <Col md={4}>
                        <Btn attrBtn={{color: 'primary', className: "mb-2 w-100", href: `${API_CDN_DEFAULT}/download?load=${accountData.last_education_certificate_files_id}`, target: "_blank"}}>Download Education Certificate</Btn>
                    </Col>
                    <hr/>
                    <Col md={12}>
                        <Btn attrBtn={{color: 'light', className: "mt-3 mb-2 w-100", onClick: () => onFinish()}}>Close</Btn>
                    </Col>
                </Row>
            </Row>


        </Form>
    </>
}