import {Col, FormGroup, Input, InputGroup, InputGroupText, Label, Row} from "reactstrap";
import {ExamTypeahead} from "../../../Components/Typeahead/ExamTypeahead";
import {Btn} from "../../../AbstractElements";
import React from "react";
import axios from "axios";
import {API_COURSE_BATCH} from "../../../api/service";
import SweetAlert from "sweetalert2";
import {handlerSetFormNumeric} from "../../../Utils/forms";
import DatePicker from "react-datepicker";
import {parseDate} from "../../../Utils/date";

export const BatchFinalExamAssignTab = ({data, setData, refresher, setRefresher, loadBatchData}) => {
    const updateBatchData = () => {
        axios.put(API_COURSE_BATCH + "/", {
            ...data
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Data Saving Completed!',
                    icon: 'success'
                }).then(() => {
                    loadBatchData()
                }).then(() => {
                    setRefresher({
                        ...refresher,
                        user_typeahead: true,
                    })
                })
            } else {
                throw r
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Data Saving Failed!',
                icon: 'error'
            })
        })
    }


    return <>
        <Row>
            <Col md={4} xs={12}>
                <FormGroup>
                    <Label>Exam Time Limit</Label>
                    <InputGroup className="mb-3">
                        <Input min={0} required name="exam_time" value={data.exam_time}
                               onChange={(e) => handlerSetFormNumeric(e, data, setData)}
                               className="form-control btn-square" type="number"
                               placeholder="Type Something"/>
                        <InputGroupText>{'Minutes'}</InputGroupText>
                    </InputGroup>
                </FormGroup>
            </Col>
            <Col md={4} xs={12}>
                <Label>Exam Start Date</Label>
                <DatePicker className="form-control digits" selected={parseDate(data.exam_start_date)}
                            onChange={(date) => {
                                setData({
                                    ...data,
                                    exam_start_date: date.toISOString()
                                })
                            }}/>
            </Col>
            <Col md={4} xs={12}>
                <Label>Exam Due Date</Label>
                <DatePicker className="form-control digits" selected={parseDate(data.exam_due_date)}
                            onChange={(date) => {
                                setData({
                                    ...data,
                                    exam_due_date: date.toISOString()
                                })
                            }}/>
            </Col>
        </Row>


        <FormGroup>
            <Label>Final Exam</Label>
            <ExamTypeahead
                courseID={data.course_id}
                defaultID={data.exam_id}
                onChange={(e) => {
                    setData({
                        ...data,
                        exam_id: e.id
                    });
                }}
                refresh={refresher.exam_typeahead}
                onRefreshFinish={() => setRefresher({
                    ...refresher,
                    exam_typeahead: false
                })}/>
        </FormGroup>
        <Btn attrBtn={{
            color: 'primary',
            className: 'm-b-15',
            type: "button",
            onClick: () => {
                updateBatchData()
            }
        }}>
            Save
        </Btn>

    </>
}