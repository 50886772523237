import {Card, Col, FormGroup, Input, Label, Row} from "reactstrap";
import {CourseModuleGroupTypeahead} from "../../../Components/Typeahead/CourseModuleGroupTypeahead";
import {Btn} from "../../../AbstractElements";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {API_COURSE_BATCH} from "../../../api/service";
import SweetAlert from "sweetalert2";


export const CourseModulesAssignTab = ({data, setData, refresher, setRefresher, loadBatchData}) => {
    const updateBatchData = () => {
        axios.put(API_COURSE_BATCH + "/", {
            ...data,
            modules: selectedModules
        }).then(r => {
            if (r.status === 204) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Data Saving Completed!',
                    icon: 'success'
                }).then(() => {
                    loadBatchData()
                }).then(() => {
                    setRefresher({
                        ...refresher,
                        user_typeahead: true,
                    })
                })
            } else {
                throw r
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Data Saving Failed!',
                icon: 'error'
            })
        })
    }


    const [selectedModules, setSelectedModules] = useState([])

    const initialSelectedModuleData = {
        module_group_id: 0,
        order: 1,
    }

    useEffect(() => {
        console.log("DM", data)
        if (data.modules !== undefined && data.modules !== null) {
            setSelectedModules([...data.modules])
        }
    }, [data.modules])

    return <>
        {selectedModules && selectedModules.map((v, idx) => {
            return (
                <Card style={{padding: 16}}>
                    <FormGroup>
                        <Label>Order</Label>
                        <Input min={0} required name="order" value={selectedModules[idx].order}
                               onChange={(e) => {
                                   let currentData = selectedModules


                                   currentData[idx] = {
                                       ...currentData[idx], order: parseInt(e.target.value)
                                   }


                                   console.log(currentData)
                                   console.log(currentData[idx])
                                   console.log(v)
                                   setSelectedModules([...currentData])
                               }}
                               className="form-control btn-square" type="number"
                               placeholder="Type Something"/>
                    </FormGroup>
                    <FormGroup>
                        <Label>Module Name</Label>
                        <CourseModuleGroupTypeahead
                            courseID={data.course_id}
                            defaultID={v.module_group_id}
                            onChange={(e) => {
                                let currentData = selectedModules
                                currentData[idx] = {...currentData[idx], module_group_id: e.id}
                                setSelectedModules([...currentData])
                            }}
                            refresh={refresher.module_group_typeahead}
                            onRefreshFinish={() => setRefresher({
                                ...refresher,
                                module_group_typeahead: false
                            })}/>
                    </FormGroup>
                </Card>
            )
        })}

        <Row>
            <Col md={12}>
                <Btn attrBtn={{
                    color: 'secondary',
                    className: 'm-b-15',
                    style: {width: "100%"},
                    type: "button",
                    onClick: () => {
                        setSelectedModules([...selectedModules, {
                            ...initialSelectedModuleData,
                            order: selectedModules.length
                        }])
                    }
                }}>
                    Add Module
                </Btn>
            </Col>
        </Row>


        <Btn attrBtn={{
            color: 'primary',
            className: 'm-b-15',
            type: "button",
            onClick: () => {
                updateBatchData()
            }
        }}>
            Save
        </Btn>

    </>
}