import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import {Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, Row, Table} from "reactstrap";
import {Btn, H5} from "../../AbstractElements";
import {TableActionStyle} from "../../Utils/table";
import axios from "axios";
import {API_COURSE_BATCH, API_COURSE_EXAM_SUBMISSION, API_COURSE_EXAM_SUBMISSION_REPORT} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import {CountPaginationOffset} from "../../Utils/pagination";
import Select from "react-select";
import {CSVDownload, CSVLink} from "react-csv";

export const CourseBatchDataTable = ({courseId = 0, drawerState, setDrawerState, refresh = false, onRefreshFinish}) => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false)
    const [pagination, setPagination] = useState({
        rowsPerPage: 10,
        page: 1,
        totalRows: 10
    })

    useEffect(() => {
        setFilter({
            ...filter,
            course_id: courseId
        })
    }, [courseId])

    const [filter, setFilter] = useState({
        title: "",
        course_id: courseId
    })

    useEffect(() => {
        if (refresh === true) {
            loadCourseBatchData(pagination.rowsPerPage, 0, {...filter})
            onRefreshFinish()
        }
    }, [refresh])


    const loadCourseBatchData = (limit = 10, offset = 0, filter = {}) => {
        setIsLoading(true);
        axios.get(API_COURSE_BATCH + "/?pagination=" + CreatePaginationEncoding(limit, offset) + "&filter=" + CreateFilterEncoding(filter)).then(r => {
            if (r.status === 200) {
                return r.data["data"]
            }
        }).then(json => {
            if (json.data) {
                setData(json.data.length > 0 ? json.data : [])
                setPagination({
                    ...pagination, totalRows: json.count.all
                })
            } else {
                setData([])
                setPagination({
                    ...pagination, totalRows: 0
                })
            }
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        loadCourseBatchData(10, 0, {...filter, course_id: courseId})
    }, [courseId])


    const onSearchSubmit = (e) => {
        e.preventDefault()
        loadCourseBatchData(pagination.rowsPerPage, 0, {...filter})
    }

    const onSort = (column, sortDir) => {
        console.log(column, sortDir);
    }

    const onPageChange = page => {
        loadCourseBatchData(pagination.rowsPerPage, CountPaginationOffset(page, pagination.rowsPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    };


    const onPaginationRowsChangePage = (newPerPage, page) => {
        loadCourseBatchData(newPerPage, CountPaginationOffset(page, newPerPage), {...filter})
        setPagination({
            ...pagination, page: page
        })
    }


    const [batchesOptions, setBatchesOption] = useState([])
    const [selectedBatchID, setSelectedBatchID] = useState(0)
    const [reportData, setReportData] = useState({
        "user_name_sequence": [],
        "submission_data" : {}
    })
    const [submissionKey, setSubmissionKey] = useState([])
    useEffect(() => {
        const key = Object.keys(reportData.submission_data)
        setSubmissionKey([...key])
    }, [reportData])

    useEffect(() => {
        buildCSVData()
    }, [submissionKey])

    useEffect(() => {
        axios.get(API_COURSE_BATCH + `/?filter=${CreateFilterEncoding({course_id : courseId})}`).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }) .then ((json) => {
            let newBatchOptions = []

            if (json.data && json.data.data) {
                json.data.data.map((v,i) => {
                    newBatchOptions.push({
                        label : v.title,
                        value : v.id,
                    })
                })
                setBatchesOption(newBatchOptions)
            }
        })
    }, [courseId])

    useEffect(() => {
        axios.get(API_COURSE_EXAM_SUBMISSION_REPORT + `/${selectedBatchID}/v2`).then((r) => {
            if (r.status === 200) {
                return r.data
            }
        }) .then ((json) => {
            if (json.data && json.data.data && json.data.data.submission_data && json.data.data.user_name_sequence) {
                setReportData({
                    ...json.data.data
                })
            }
        })
    }, [selectedBatchID])

    const [csvData, setCsvData] = useState([])

    const buildCSVData = () => {
        // [reportData.user_name_sequence, ...reportData.submission_data]
        let res = [reportData.user_name_sequence]
        submissionKey && submissionKey.map((v,i) => {
            reportData.submission_data && reportData.submission_data[v] && reportData.submission_data[v].map((v,i) => {
                res.push(v)
            })
        })

        setCsvData(res)

        return res
    }

    return <>
        <Container fluid={true} className="datatables">
            <Row>
                <Col sm="12">
                    <Card>
                        <CardHeader className="pb-0">
                            <Row className="justify-content-between">
                                <Col md={"auto"}>
                                    <H5>Exam Report</H5>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <Col md={12}>
                                <Form onSubmit={onSearchSubmit}>
                                    <Row className="justify-content-between align-items-end">
                                        <Col md="6">
                                            <FormGroup>
                                                <Label>Select Batch</Label>
                                                <Select
                                                    options={batchesOptions}
                                                    value={batchesOptions.find((e) => e.value === batchesOptions.name)}
                                                    className="js-example-basic-single col-sm-12"
                                                    onChange={(data) => {
                                                        setSelectedBatchID(data.value)
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>

                                        <Col md="auto">
                                            {/*<button onClick={() => buildCSVData()}>Test CSV</button>*/}
                                            {/*<CSVLink data={[reportData.user_name_sequence, ...reportData.submission_data]} className='btn btn-primary' type='button' >Export</CSVLink>*/}
                                            <CSVLink data={csvData} className='btn btn-primary' type='button' >Export</CSVLink>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>


                            <Col md={12}  style={{
                                overflow: "auto"
                            }}>
                                <Table>
                                {
                                    submissionKey && submissionKey.map((v,i) => {
                                        return <>
                                            <thead>
                                            <tr>
                                                {
                                                    reportData.user_name_sequence && reportData.user_name_sequence.map((v,i) => {
                                                        return <th> {v} </th>
                                                    })
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr style={{
                                                backgroundColor: "#FFA500"
                                            }}>
                                                <td colSpan={reportData.submission_data[v] ? reportData.submission_data[v].length-1 : 1}>
                                                    <strong>{v}</strong>
                                                </td>
                                            </tr>

                                            {reportData.submission_data && reportData.submission_data[v] && reportData.submission_data[v].map((vv, i) => {
                                                return <tr>
                                                    {
                                                        vv.map((vvv, i) => {
                                                            return <td>{vvv}</td>
                                                        })
                                                    }
                                                </tr>
                                            })}
                                            </tbody>
                                        </>

                                    })
                                    }
                                </Table>
                            </Col>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
}