import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import {API_CDN_DEFAULT, API_CONTENT_TESTIMONIAL, API_USER} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import {FilePond} from "react-filepond";

export const TestimonialForms = ({id = 0, refreshState, setRefreshState, onFinish}) => {
    const initialData = {
        id : 0,
        avatar_id : "",
        name : "",
        role : "",
        text : ""
    }
    const roleOptions = [
        {value: 1, label: 'User'},
        {value: 2, label: 'Admin'},
    ]
    const genderOptions = [
        {value: 1, label: 'Male'},
        {value: 2, label: 'Female'},
    ];

    const [testimonialData, setTestimonialData] = useState(initialData)

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_CONTENT_TESTIMONIAL + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setTestimonialData(json.data[0])
                    setAvatarFiles([{
                        source: json.data[0].avatar_id,
                        options: {type: 'local'}
                    }])
                }
            })
        } else {
            setTestimonialData(initialData)
        }
    }, [id])

    const handlerSubmit = (e) => {
        e.preventDefault()

        reference.avatarFiles.current.processFile().then((f) => {
            if (id === 0) {
                axios.post(API_CONTENT_TESTIMONIAL + "/", {...testimonialData, avatar_id: f.serverId}, {withCredentials: true}).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Success!',
                            text: 'Data Saving Completed!',
                            icon: 'success'
                        }).then(() => {
                            setRefreshState({
                                ...refreshState,
                                userDataTable: true
                            })
                            onFinish()
                        })
                    } else {
                        throw r
                    }
                }).catch((err) => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Saving Failed!',
                        icon: 'error'
                    })
                })
            } else {
                axios.put(API_CONTENT_TESTIMONIAL + "/", {...testimonialData, avatar_id: f.serverId}, {withCredentials: true}).then(r => {
                    if (r.status === 204) {
                        SweetAlert.fire({
                            title: 'Success!',
                            text: 'Data Saving Completed!',
                            icon: 'success'
                        }).then(() => {
                            setRefreshState({
                                ...refreshState,
                                userDataTable: true
                            })
                            onFinish()
                        })
                    } else {
                        throw r
                    }
                }).catch((err) => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Saving Failed!',
                        icon: 'error'
                    })
                })
            }
        })
    }

    const [reference, setReference] = useState({
        avatarFiles: useRef(null)
    })

    const [avatarFiles, setAvatarFiles] = useState([])


    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} User</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <Row>
                <Col md={12}>
                    <FormGroup>
                        <Label>Module File</Label>
                        <FilePond
                            required
                            ref={reference.avatarFiles}
                            files={avatarFiles}
                            allowMultiple={false}
                            onupdatefiles={(newFile) => {
                                setAvatarFiles(newFile)
                            }}

                            instantUpload={false}
                            maxFiles={1}
                            imageCropAspectRatio={'1:1'}
                            acceptedFileTypes={['image/*', 'application/pdf']}

                            maxFileSize={'10MB'}
                            server={API_CDN_DEFAULT}
                            name="files"
                            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '
                        />
                    </FormGroup>
                </Col>

                <Col md={12}>
                    <FormGroup className="mb-3">
                        <Label className="form-label">Name</Label>
                        <Input className="form-control" placeholder="Name"
                               value={testimonialData.name}
                               onChange={(e) => setTestimonialData({...testimonialData, name: e.target.value})}
                               type={"text"}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup className="mb-3">
                        <Label className="form-label">Role</Label>
                        <Input className="form-control" placeholder="Role"
                               value={testimonialData.role}
                               onChange={(e) => setTestimonialData({...testimonialData, role: e.target.value})}
                               type={"text"}
                        />
                    </FormGroup>
                </Col>
                <Col md={12}>
                    <FormGroup className="mb-3">
                        <Label className="form-label">Text</Label>
                        <Input className="form-control" placeholder="Text"
                               value={testimonialData.text}
                               onChange={(e) => setTestimonialData({...testimonialData, text: e.target.value})}
                               type={"text"}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                type: 'submit',
            }}
            >Submit</Btn>
            <Btn attrBtn={{color: 'light', onClick: () => onFinish()}}>Cancel</Btn>
        </Form>
    </>
}