import {Card, CardBody, CardFooter, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import React, {useEffect, useState} from "react";
import {handlerSetFormString} from "../../Utils/forms";
import axios from "axios";
import {API_COURSE_EXAM} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";
import {Trash} from "react-feather";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import {CKECustomUploadAdapterPlugin} from "../../Components/Plugins/ck5_upload_adapter";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export const CourseExamQuestionForms = ({
                                            id = 0,
                                            courseID = 0,
                                            refresher,
                                            setRefresher,
                                            drawerState,
                                            setDrawerState
                                        }) => {
    const initialData = {
        title: "",
        course_id: courseID,
        questions: []
    }
    const questionInitialData = {
        id: `${Math.round((new Date()).getTime() / 1000)}`,
        question: "",
        answers: [],
    }

    const questionAnswersInitialData = {
        content: "",
        points: 0
    }

    const [data, setData] = useState(initialData)
    const [editorData, setEditorData] = useState('')

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_COURSE_EXAM + "/with-question?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setData(json.data[0])
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    useEffect(() => {
        setData({
            ...initialData,
            course_id: courseID
        })
    }, [courseID])

    const closeDrawer = () => {
        setDrawerState({...drawerState, questionForms: false})
    }

    const handlerSubmit = (e) => {
        e.preventDefault()
        axios.put(API_COURSE_EXAM + "/with-question", {...data}).then(r => {
            if (r.status === 204) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: 'Data Saving Completed!',
                    icon: 'success'
                }).then(() => {
                    closeDrawer()
                })
            } else {
                throw r
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Data Saving Failed!',
                icon: 'error'
            })
        })
    }

    return <>
        <div className="m-b-30">
            <H4>Course Exam Question</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Exam Name</Label>
                        <Input required disabled={true} name="title" value={data.title}
                               onChange={(e) => handlerSetFormString(e, data, setData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>
                </Col>
            </Row>
            <hr/>
            <div className="m-b-30">
                <H4>Exam Question</H4>
                <hr/>
            </div>
            {data && data.questions && data.questions.map((v, i) => {
                return (
                    <Card>
                        <CardBody>
                            <Row className="justify-content-end">
                                <Col md={1}>
                                    <Btn attrBtn={{
                                        style: {width: "100%"},
                                        color: 'danger',
                                        onClick: () => {
                                            let newQuestionArr = data.questions
                                            newQuestionArr.splice(i, 1)

                                            setData({
                                                ...data,
                                                questions: [...newQuestionArr]
                                            })
                                        }
                                    }}>
                                        <Trash size={16}/>
                                    </Btn>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={12}>
                                    <FormGroup>
                                        <Label>Question (ID : {data.questions[i].id})</Label>

                                        <CKEditor
                                            editor={ClassicEditor}
                                            data={data.questions[i].question}
                                            config={{
                                                extraPlugins: [CKECustomUploadAdapterPlugin],
                                                toolbar: {
                                                    items: [
                                                        'heading',
                                                        '|',
                                                        'bold',
                                                        'italic',
                                                        'link',
                                                        'bulletedList',
                                                        'numberedList',
                                                        '|',
                                                        'alignment',
                                                        'outdent',
                                                        'indent',
                                                        'uploadImage',
                                                        'blockQuote',
                                                        'mediaEmbed',
                                                        'undo',
                                                        'redo'
                                                    ]
                                                },
                                                mediaEmbed: {
                                                    previewsInData: true
                                                }
                                            }}
                                            onChange={(event, editor) => {
                                                const editorData = editor.getData();

                                                let newQuestion = data.questions;
                                                newQuestion[i].question = editorData
                                                setData({
                                                    ...data,
                                                    questions: newQuestion
                                                })

                                                // setEditorData(data)
                                            }}
                                        />

                                        {/*<Input required name="title" value={data.questions[i].question}*/}
                                        {/*       onChange={(e) => {*/}
                                        {/*           let newQuestion = data.questions;*/}
                                        {/*           newQuestion[i].question = e.target.value*/}
                                        {/*           setData({*/}
                                        {/*               ...data,*/}
                                        {/*               questions: newQuestion*/}
                                        {/*           })*/}
                                        {/*       }}*/}
                                        {/*       className="form-control btn-square" type="text"*/}
                                        {/*       placeholder="Type Something"/>*/}
                                    </FormGroup>
                                    <hr/>

                                    {v.answers && v.answers.length > 0 &&
                                        v.answers.map((vv, ii) => {
                                            return (

                                                <Row className="justify-content-center">
                                                    <Col md={1} className="m-b-15">
                                                        <FormGroup>
                                                            <Label>Code</Label>

                                                            <Input required name="code" value={vv.code}
                                                                   onChange={(e) => {
                                                                       let newQuestion = data.questions;

                                                                       newQuestion[i].answers[ii].code = e.target.value;

                                                                       setData({
                                                                           ...data,
                                                                           questions: newQuestion
                                                                       })
                                                                   }}
                                                                   className="form-control btn-square" type="text"
                                                                   placeholder="Type Something"/>
                                                        </FormGroup>
                                                    </Col>


                                                    <Col md={9} className="m-b-15">
                                                        <FormGroup>
                                                            <Label>Content</Label>

                                                            <CKEditor
                                                                editor={ClassicEditor}
                                                                data={vv.content}
                                                                config={{
                                                                    extraPlugins: [CKECustomUploadAdapterPlugin],
                                                                    toolbar: {
                                                                        items: [
                                                                            'heading',
                                                                            '|',
                                                                            'bold',
                                                                            'italic',
                                                                            'link',
                                                                            'bulletedList',
                                                                            'numberedList',
                                                                            '|',
                                                                            'alignment',
                                                                            'outdent',
                                                                            'indent',
                                                                            'uploadImage',
                                                                            'blockQuote',
                                                                            'mediaEmbed',
                                                                            'undo',
                                                                            'redo'
                                                                        ]
                                                                    },
                                                                    mediaEmbed: {
                                                                        previewsInData: true
                                                                    }
                                                                }}
                                                                onChange={(event, editor) => {
                                                                    const editorData = editor.getData();

                                                                    let newQuestion = data.questions;

                                                                    newQuestion[i].answers[ii].content = editorData;

                                                                    setData({
                                                                        ...data,
                                                                        questions: newQuestion
                                                                    })

                                                                    // setEditorData(data)
                                                                }}
                                                            />


                                                            {/*<Input required name="title" value={vv.content}*/}
                                                            {/*       onChange={(e) => {*/}
                                                            {/*           let newQuestion = data.questions;*/}

                                                            {/*           newQuestion[i].answers[ii].content = e.target.value;*/}

                                                            {/*           setData({*/}
                                                            {/*               ...data,*/}
                                                            {/*               questions: newQuestion*/}
                                                            {/*           })*/}
                                                            {/*       }}*/}
                                                            {/*       className="form-control btn-square" type="text"*/}
                                                            {/*       placeholder="Type Something"/>*/}
                                                        </FormGroup>
                                                    </Col>

                                                    <Col md={2} className="m-b-15">
                                                        <Label>Points</Label>
                                                        <Input required name="title" value={vv.points}
                                                               onChange={(e) => {
                                                                   let newQuestion = data.questions;

                                                                   newQuestion[i].answers[ii].points = parseInt(e.target.value);

                                                                   setData({
                                                                       ...data,
                                                                       questions: newQuestion
                                                                   })
                                                               }}
                                                               className="form-control btn-square" type="number"
                                                               placeholder="Type Something"/>
                                                    </Col>
                                                </Row>
                                            )
                                        })

                                    }
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter>
                            <Btn attrBtn={{
                                style: {width: "100%"},
                                color: 'primary',
                                className: 'm-t-15',
                                onClick: () => {
                                    let q = data.questions;

                                    q[i] = {
                                        ...q[i],
                                        answers: [...q[i].answers, questionAnswersInitialData]
                                    }

                                    setData({
                                        ...data,
                                        questions: [...q]
                                    })
                                }
                            }}>
                                Add Answer
                            </Btn>
                        </CardFooter>
                    </Card>
                )

            })}
            <Btn attrBtn={{
                style: {width: "100%"},
                color: 'secondary',
                className: 'm-t-15',
                onClick: () => {
                    if (data.questions !== undefined && data.questions !== null) {
                        setData({
                            ...data,
                            questions: [...data.questions, questionInitialData]
                        })
                    } else {
                        setData({
                            ...data,
                            questions: [questionInitialData]
                        })
                    }


                }
            }}>
                Add Question
            </Btn>
            <hr/>
            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                type: 'submit',
            }}>
                Submit
            </Btn>
            <Btn attrBtn={{
                color: 'light', type: "button", onClick: () => closeDrawer()
            }}>Cancel</Btn>
        </Form>
    </>
}