import {FormGroup, Label} from "reactstrap";
import {UserTypeahead} from "../../../Components/Typeahead/UserTypeahead";
import {Btn, H4} from "../../../AbstractElements";
import React from "react";
import {UserDataTable} from "../../../Components/DataTables/UserDataTable";
import {TableActionStyle} from "../../../Utils/table";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {API_COURSE_BATCH} from "../../../api/service";

export const ParticipantAssignTab = ({setNewParticipant, refresher, setRefresher, data, setData}) => {
    const columns = [
        {
            name: 'ID',
            selector: (row) => row.id,
            sortable: true,
            center: true,
            compact: true,
            serverSort: "id",
        },
        {
            name: 'Name',
            selector: (row) => row.full_name,
            sortable: true,
            center: true,
        },
        {
            name: 'Action',
            selector: (row) => tableAction(row.id),
            sortable: true,
            center: true,
        },
    ]


    const tableAction = (id) => {
        return (<div>
                    <span>
                        <Btn attrBtn={{
                            onClick: () => handleDelete(id),
                            style: TableActionStyle,
                            className: 'btn btn-danger btn-xs',
                            type: 'button'
                        }}>Remove</Btn>
                    </span>
        </div>)
    }

    const handleDelete = (id) => {
        SweetAlert.fire({
            title: 'Delete?',
            html: 'Are you sure want to delete this?',
            icon: 'error',
            cancelButtonText: 'Cancel',
            confirmButtonText: 'Delete',
            reverseButtons: true,
            showCancelButton: true,
        }).then((e) => {
            if (e.isConfirmed) {

                axios.post(API_COURSE_BATCH + "/revoke-user", {
                    new_participant_ids: [id],
                    batch_id: data.id
                })
                    .then(r => {
                        if (r.status === 204) {
                            SweetAlert.fire({
                                title: 'Success!',
                                text: 'Data Saving Completed!',
                                icon: 'success'
                            }).then(() => {

                            })
                        } else {
                            throw r
                        }
                    })
                    .then(() => {
                        setData({
                            ...data,
                            participant_ids: data.participant_ids.filter((e) => e !== id),
                        })

                    })
                    .then(() => {
                        console.log("PARID", data.participant_ids)


                        setRefresher({
                            ...refresher,
                            participant_datatable: true
                        })
                    })
                    .catch((err) => {
                        SweetAlert.fire({
                            title: 'Failed!',
                            text: 'Data Saving Failed!',
                            icon: 'error'
                        })
                    })

                // axios.delete(API_USER + "/?id=" + id).then(r => {
                //     if (r.status === 200) {
                //         return r.data["data"]
                //     }
                // }).then(json => {
                //     SweetAlert.fire({
                //         title: 'Success!',
                //         text: 'Data Deletion Completed!',
                //         icon: 'success'
                //     }).then(() => {
                //         setRefresher({...refresher, participant_datatable: true})
                //     })
                // }).catch(() => {
                //     SweetAlert.fire({
                //         title: 'Failed!',
                //         text: 'Data Deletion Failed!',
                //         icon: 'error'
                //     })
                // })
            }
        });
    }

    return <>
        <FormGroup>
            <Label>Assign User</Label>
            <UserTypeahead ignoreIDs={data.participant_ids} onChange={(e) => {
                setNewParticipant(e)
            }} refresh={refresher.user_typeahead}
                           onRefreshFinish={() => setRefresher({
                               ...refresher,
                               user_typeahead: false
                           })}/>
        </FormGroup>

        <Btn attrBtn={{
            color: 'primary',
            className: 'm-r-15',
            type: 'submit',
        }}>
            Assign
        </Btn>

        <hr/>

        <H4>Batch Participants</H4>
        <hr/>


        {data.participant_ids && data.participant_ids.length > 0 && <UserDataTable customColumns={columns} dataLoadFilter={{ids: data.participant_ids}}
                        refresh={refresher.participant_datatable}
                        onRefreshFinish={() => {
                            setRefresher({...refresher, participant_datatable: false})
                        }}/>}
    </>
}