// const HOST = "http://localhost:4488"
// const HOST = "https://projects.zukff.com/009/api"
// const HOST = "https://api.wsc.zukff.com"
// const HOST = "https://api.weldingstudycenter.com"

const HOST = process.env.REACT_APP_API_HOST

export const API_DATASTORE = `${HOST}/datastore`

export const API_USER = `${API_DATASTORE}/user`

export const API_USER_CURRENT = API_USER + '/current'

export const API_USER_CERTIFICATE = `${API_DATASTORE}/user-certificate`

export const API_USER_LOGIN = API_USER + '/authorize'

export const API_CONTENT = `${API_DATASTORE}/content`

export const API_CONTENT_EVENT = `${API_CONTENT}/events`

export const API_CONTENT_SERVICE = `${API_CONTENT}/services`

export const API_CONTENT_CONFIGURATOR = `${API_CONTENT}/configurator`

export const API_CONTENT_TESTIMONIAL = `${API_CONTENT}/testimonial`

export const API_USER_ABSENCE = `${API_DATASTORE}/user-absence`

export const API_COURSE = `${API_DATASTORE}/course`

export const API_COURSE_BATCH = `${API_DATASTORE}/course-batch`

export const API_COURSE_BATCH_CLASS = `${API_DATASTORE}/course-batch-class`

export const API_COURSE_EXAM = `${API_DATASTORE}/course-exam`

export const API_COURSE_EXAM_REPORT = `${API_COURSE_EXAM}/report`

export const API_COURSE_EXAM_SUBMISSION = `${API_DATASTORE}/course-exam-submission`
export const API_COURSE_EXAM_SUBMISSION_REPORT = `${API_COURSE_EXAM_SUBMISSION}/report`

export const API_COURSE_MODULE_GROUPS = `${API_DATASTORE}/course-module-groups`

export const API_COURSE_MODULE = `${API_DATASTORE}/course-modules`


export const API_CDN = HOST + '/cdn'

export const API_CDN_DEFAULT = API_CDN + "/default"