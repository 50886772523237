import React, {Fragment, useState} from "react";
import {Btn, H4} from "../../../../AbstractElements";
import {CardBody, CardFooter, CardHeader, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import {EditProfile, UpdateProfile} from '../../../../Constant';
import axios from "axios";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {parseDate} from "../../../../Utils/date";
import Select from "react-select";
import {FilePond} from "react-filepond";
import {API_CDN_DEFAULT, API_USER} from "../../../../api/service";
import {genderOptions} from "./gender_options";

const EditMyProfile = ({
                           accountData,
                           setAccountData,
                           filesPhoto,
                           setFilesPhoto,
                           filesCV,
                           setFilesCV,
                           filesEducationCertificate,
                           setFilesEducationCertificate
                       }) => {

    const handleSubmit = (e) => {
        e.preventDefault()
        submitChanges(accountData)
    }

    const submitChanges = (data) => {
        axios.put(API_USER + "/", {
            ...data
        }, {withCredentials: true}).then(r => {
            if (r.status === 204) {
                SweetAlert
                    .fire({title: 'Good job!', text: 'We\'ve record your data!', icon: 'success'})
                    .then(() => {
                    })
            }
        }).catch((err) => {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Hang up, we have some errors.',
                icon: 'error'
            }).then(() => {
            })
        })
    }

    const [birthDate, setBirthDate] = useState(new Date())
    const handleBirthDateChange = date => {
        setBirthDate(date);
        setAccountData({
            ...accountData,
            birth_date: date.toISOString()
        })
    };

    const handleFinishProcess = (err, file, name) => {
        if (err !== null) {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Hang up, we have some errors.',
                icon: 'error'
            }).then(() => {
            })
        } else {
            submitChanges({
                ...accountData,
                [name]: file.serverId
            })
            setAccountData({
                ...accountData,
                [name]: file.serverId
            })
        }
    }
    const handleRemoveFiles = (err, file, name) => {
        if (err !== null) {
            SweetAlert.fire({
                title: 'Failed!',
                text: 'Hang up, we have some errors.',
                icon: 'error'
            }).then(() => {
            })
        } else {
            axios.delete(API_CDN_DEFAULT, {
                withCredentials: true,
                data: file.serverId
            }).then((res) => {
                if (res.status === 200) {
                    submitChanges({
                        ...accountData,
                        [name]: ""
                    })
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Hang up, we have some errors.',
                    icon: 'error'
                }).then(() => {
                })
            }).finally(() => {
                setAccountData({
                    ...accountData,
                    [name]: ""
                })
            })
        }
    }
    return (
        <Fragment>
            <Form className="card" onSubmit={handleSubmit}>
                <CardHeader className="pb-0">
                    <H4 attrH4={{className: "card-title mb-0"}}>{EditProfile}</H4>
                    <div className="card-options">
                        <a className="card-options-collapse" href="#javascript">
                            <i className="fe fe-chevron-up"></i>
                        </a>
                        <a className="card-options-remove" href="#javascript">
                            <i className="fe fe-x"></i>
                        </a>
                    </div>
                </CardHeader>
                <CardBody>
                    <Row>
                        {/*<Col md={4}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Photo (3x4 - Red Background)</Label>*/}
                        {/*        <FilePond*/}
                        {/*            required*/}
                        {/*            files={filesPhoto}*/}
                        {/*            allowMultiple={false}*/}
                        {/*            onremovefile={(err, file) => handleRemoveFiles(err, file, "photo_files_id")}*/}
                        {/*            onprocessfile={(err, file) => handleFinishProcess(err, file, "photo_files_id")}*/}
                        {/*            onupdatefiles={(newFile) => {*/}
                        {/*                setFilesPhoto(newFile)*/}
                        {/*            }}*/}

                        {/*            maxFiles={1}*/}
                        {/*            imageCropAspectRatio={'3:4'}*/}
                        {/*            acceptedFileTypes={['image/*']}*/}

                        {/*            allowImageCrop={true}*/}
                        {/*            allowImageTransform={true}*/}
                        {/*            allowFileSizeValidation={true}*/}

                        {/*            maxFileSize={'2MB'}*/}
                        {/*            server={API_CDN_DEFAULT}*/}
                        {/*            name="files"*/}
                        {/*            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '*/}
                        {/*        />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        {/*<Col md={4}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Curriculum Vitae</Label>*/}
                        {/*        <FilePond*/}
                        {/*            required*/}
                        {/*            files={filesCV}*/}
                        {/*            allowMultiple={false}*/}

                        {/*            onremovefile={(err, file) => handleRemoveFiles(err, file, "cv_files_id")}*/}
                        {/*            onprocessfile={(err, file) => handleFinishProcess(err, file, "cv_files_id")}*/}
                        {/*            onupdatefiles={(newFile) => {*/}
                        {/*                setFilesCV(newFile)*/}
                        {/*            }}*/}

                        {/*            maxFiles={1}*/}
                        {/*            acceptedFileTypes={["application/pdf"]}*/}

                        {/*            allowFileSizeValidation={true}*/}

                        {/*            maxFileSize={'2MB'}*/}
                        {/*            server={API_CDN_DEFAULT}*/}
                        {/*            name="files"*/}
                        {/*            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '*/}
                        {/*        />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        {/*<Col md={4}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Education Certificate</Label>*/}
                        {/*        <FilePond*/}
                        {/*            required*/}
                        {/*            files={filesEducationCertificate}*/}
                        {/*            allowMultiple={false}*/}

                        {/*            onremovefile={(err, file) => handleRemoveFiles(err, file, "last_education_certificate_files_id")}*/}
                        {/*            onprocessfile={(err, file) => handleFinishProcess(err, file, "last_education_certificate_files_id")}*/}
                        {/*            onupdatefiles={(newFile) => {*/}
                        {/*                setFilesEducationCertificate(newFile)*/}
                        {/*            }}*/}

                        {/*            maxFiles={1}*/}
                        {/*            acceptedFileTypes={['image/*', "application/pdf"]}*/}

                        {/*            allowFileSizeValidation={true}*/}
                        {/*            maxFileSize={'2MB'}*/}
                        {/*            server={API_CDN_DEFAULT}*/}
                        {/*            name="files"*/}
                        {/*            labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span> '*/}
                        {/*        />*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        <hr className={"m-t-15"}/>
                        <Col md={12}>
                            <FormGroup>
                                <Label>Full Name</Label>
                                <Input
                                    value={accountData.full_name}
                                    onChange={(e) => setAccountData({...accountData, full_name: e.target.value})}
                                    className="form-control" type="text" required="" placeholder="Full Name"/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Birth Date</Label>
                                <DatePicker value={parseDate(accountData.birth_date)} className="form-control digits"
                                            selected={parseDate(accountData.birth_date)}
                                            onChange={handleBirthDateChange}/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Gender</Label>
                                <Select
                                    options={genderOptions}
                                    value={genderOptions.find((e) => e.value === accountData.gender)}
                                    className="js-example-basic-single col-sm-12"
                                    onChange={(gender) => {
                                        setAccountData({
                                            ...accountData,
                                            gender: gender.value
                                        })
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        {/*<Col md={12}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Address</Label>*/}
                        {/*        <Input*/}
                        {/*            value={accountData.address}*/}
                        {/*            onChange={(e) => setAccountData({...accountData, address: e.target.value})}*/}
                        {/*            className="form-control" type="textarea" required="" placeholder="Address"/>*/}
                        {/*    </FormGroup>*/}


                        {/*</Col>*/}
                        {/*<hr/>*/}
                        {/*<Col md={6}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Last Education</Label>*/}
                        {/*        <Input*/}
                        {/*            value={accountData.last_education}*/}
                        {/*            onChange={(e) => setAccountData({...accountData, last_education: e.target.value})}*/}
                        {/*            className="form-control" type="text" required="" placeholder="Last Education"/>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}

                        {/*<Col md={6}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Education Major</Label>*/}
                        {/*        <Input*/}
                        {/*            value={accountData.education_major}*/}
                        {/*            onChange={(e) => setAccountData({...accountData, education_major: e.target.value})}*/}
                        {/*            className="form-control" type="text" required="" placeholder="Education Major"/>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}

                        {/*<Col md={12}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Company Name</Label>*/}
                        {/*        <Input*/}
                        {/*            value={accountData.company_name}*/}
                        {/*            onChange={(e) => setAccountData({...accountData, company_name: e.target.value})}*/}
                        {/*            className="form-control" type="text" required="" placeholder="Company Name"/>*/}
                        {/*    </FormGroup>*/}

                        {/*    <FormGroup>*/}
                        {/*        <Label>University</Label>*/}
                        {/*        <Input*/}
                        {/*            value={accountData.university}*/}
                        {/*            onChange={(e) => setAccountData({...accountData, university: e.target.value})}*/}
                        {/*            className="form-control" type="text" required="" placeholder="University"/>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                        {/*<hr/>*/}
                        {/*<Col md={6}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Phone Number</Label>*/}
                        {/*        <div className="input-group">*/}
                        {/*            <span className="input-group-text">+62</span>*/}
                        {/*            <Input*/}
                        {/*                value={accountData.phone_number}*/}
                        {/*                onChange={(e) => setAccountData({...accountData, phone_number: e.target.value})}*/}
                        {/*                className="form-control" type="text" required="" placeholder="Phone Number"/>*/}
                        {/*        </div>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}

                        {/*<Col md={6}>*/}
                        {/*    <FormGroup>*/}
                        {/*        <Label>Whatsapp Number</Label>*/}
                        {/*        <div className="input-group">*/}
                        {/*            <span className="input-group-text">+62</span>*/}
                        {/*            <Input*/}
                        {/*                value={accountData.whatsapp_number}*/}
                        {/*                onChange={(e) => setAccountData({*/}
                        {/*                    ...accountData,*/}
                        {/*                    whatsapp_number: e.target.value*/}
                        {/*                })}*/}
                        {/*                className="form-control" type="text" required="" placeholder="Whatsapp Number"/>*/}
                        {/*        </div>*/}
                        {/*    </FormGroup>*/}
                        {/*</Col>*/}
                    </Row>
                </CardBody>
                <CardFooter className="text-end">
                    <Btn attrBtn={{color: "primary", type: "submit"}}>{UpdateProfile}</Btn>
                </CardFooter>
            </Form>
        </Fragment>
    )
}
export default EditMyProfile