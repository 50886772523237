import {Breadcrumbs, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, Row} from "reactstrap";

import React, {useState} from "react";
import {SidenavManageSearchCourse} from "../../Components/Nav/SidenavManageUserCertificate";
import Drawer from "react-modern-drawer";
import {UserCertificateForms} from "./UserCertificateForms";
import {CourseBatchDataTable} from "../../Components/DataTables/CourseBatchDataTable";
import {UserCertificateDataTable} from "../../Components/DataTables/UserCertificateDataTable";

const ManageUserCertificatePage = () => {
    const [Sidebar, setSidebar] = useState(false);
    const [drawerState, setDrawerState] = useState({
        forms: false,
        editId: 0
    })


    const closeAllDrawer = () => {
        setDrawerState({
            forms: false,
            editId: 0
        })
    }

    const OnClickHandel = () => {
        setSidebar(!Sidebar);
    };

    const onSearchSubmit = (e) => {
        e.preventDefault()
    }
    const [selectedCourseID, setSelectedCourseID] = useState(0)

    const handleCourseChange = (courseID) => {
        setSelectedCourseID(parseInt(courseID))
    }

    return (
        <>
            <Breadcrumbs mainTitle="Manage User Certificate" parent="Course" title="Manage User Certificate"/>
            <Container fluid={true}>
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-6 xl-30">
                            <div className="email-sidebar">
                                <a className="btn btn-primary email-sidebar-toggle" href="#" onClick={OnClickHandel}>Course
                                    Title</a>
                                <div className={`email-left-aside ${Sidebar ? 'open' : ''}`}>
                                    <SidenavManageSearchCourse onChange={handleCourseChange}/>
                                </div>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-12 xl-70">
                            {
                                selectedCourseID === 0 ?
                                    <h3>Select Course First</h3>
                                    :  <div className="email-right-aside bookmark-tabcontent">
                                    {/*<CourseBatchDataTable courseId={selectedCourseID} drawerState={drawerState}*/}
                                    {/*                      setDrawerState={setDrawerState}/>*/}

                                    <Card>
                                        <CardHeader className="pb-0">
                                            <Row className="justify-content-between">
                                                <Col md={"auto"}>
                                                    <H5>Course Batch</H5>
                                                </Col>
                                                {/*<Col md={"auto"}>*/}
                                                {/*    <Btn attrBtn={{*/}
                                                {/*        color: "secondary",*/}
                                                {/*        className: "m-r-15",*/}
                                                {/*        onClick: (e) => setDrawerState({*/}
                                                {/*            ...drawerState,*/}
                                                {/*            forms: true,*/}
                                                {/*            editId: 0*/}
                                                {/*        })*/}
                                                {/*    }}>New Course</Btn>*/}
                                                {/*</Col>*/}
                                            </Row>
                                        </CardHeader>
                                        <CardBody>
                                            <UserCertificateDataTable courseId={selectedCourseID} drawerState={drawerState}
                                                                      setDrawerState={setDrawerState}/>
                                        </CardBody>
                                    </Card>

                                </div>
                            }

                        </Col>
                    </Row>
                </div>
            </Container>

            <Drawer
                open={drawerState.forms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <UserCertificateForms id={drawerState.editId}/>
            </Drawer>
        </>
    )

}

export default ManageUserCertificatePage