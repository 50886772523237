import {H5, P} from "../../AbstractElements";
import {Card, CardHeader, Col, Table} from "reactstrap";
import {StripedRow} from "../../Constant";
import React, {Fragment} from "react";

export const CourseExamReportDataTableExpandable = ({ data }) => {
    return <Fragment>

        <Col sm="12" className='m-b-30'>
            <Card>
                <div className="card-block row">
                    <Col sm="12" lg="12" xl="12">
                        <div className="table-responsive">
                            <Table striped>
                                <thead className="bg-primary">
                                <tr>
                                    <th scope="col">{'User ID'}</th>
                                    <th scope="col">{'Full Name'}</th>
                                    <th scope="col">{'Grade'}</th>
                                </tr>
                                </thead>
                                <tbody>

                                {/*{data.participant_grades && data.participant_grades.map((v) => {*/}
                                {/*       return <tr key={`${data.module_id}-${v.id}`}>*/}
                                {/*           <td>{v.user_id}</td>*/}
                                {/*           <td>{v.user_full_name}</td>*/}
                                {/*           <td>{v.user_total_point}</td>*/}
                                {/*       </tr>*/}
                                {/*    })}*/}

                                    <tr key={`1`}>
                                           <td>1</td>
                                           <td>Mufid</td>
                                           <td>70</td>
                                       </tr>

                                {/*{*/}
                                {/*    data.slice(0, 3).map((item) =>*/}
                                {/*        <tr key={item.id}>*/}
                                {/*            <th scope="row">{item.id}</th>*/}
                                {/*            <td>{item.first_name}</td>*/}
                                {/*            <td>{item.last_name}</td>*/}
                                {/*            <td>{item.user_name}</td>*/}
                                {/*        </tr>*/}
                                {/*    )*/}
                                {/*}*/}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </div>
            </Card>
        </Col>
    </Fragment>
};