import {Breadcrumbs, H5} from "../../AbstractElements";
import {Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Row} from "reactstrap";
import React, {useState} from "react";
import {AbsenceForms} from "./AbsenceForms";
import Drawer from 'react-modern-drawer';
import DatePicker from "react-datepicker";
import {AbsenceDataTable} from "../../Components/DataTables/AbsenceDataTable";

const ManageUserPage = () => {
    const [drawerState, setDrawerState] = useState({
        forms: false,
        batch_id: 0,
        class_id: 0,
    })

    const closeAllDrawer = () => {
        setDrawerState({
            forms: false,
            batch_id: 0,
            class_id: 0,
        })
    }

    const [selectedDate, setSelectedDate] = useState(new Date())

    return (
        <>
            <Breadcrumbs parent="Absence" title="Manage Absence" mainTitle="Manage Absence"/>
            <Container fluid={true} className="datatables">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader className="pb-0">
                                <Row className="justify-content-between">
                                    <Col md={"auto"}>
                                        <H5>Please Select Class</H5>
                                    </Col>
                                    <Col md={"auto"}>

                                        <FormGroup>
                                            <Row className="align-items-center justify-content-end">
                                                <Col md={"auto"}>
                                                    <Label className={"m-b-0"}>Date</Label>
                                                </Col>
                                                <Col md={"auto"}>
                                                    <DatePicker className="form-control digits" selected={selectedDate}
                                                                onChange={(date) => setSelectedDate(date)}/>
                                                    {/*<Input*/}
                                                    {/*    name={"date"}*/}
                                                    {/*    className="form-control digits"*/}
                                                    {/*    type="datetime-local"*/}
                                                    {/*    // value={selectedDate}*/}
                                                    {/*    onChange={(e) => {*/}
                                                    {/*        console.log(e.target.value)*/}
                                                    {/*        // setSelectedDate(e.target.value)*/}
                                                    {/*    }}*/}
                                                    {/*/>*/}
                                                </Col>


                                            </Row>

                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <AbsenceDataTable
                                    date={selectedDate.toISOString()}
                                    drawerState={drawerState}
                                    setDrawerState={setDrawerState}/>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <Drawer
                open={drawerState.forms}
                onClose={closeAllDrawer}
                direction="right"
                className={'creation-drawer'}
            >
                <AbsenceForms batchID={drawerState.batch_id} classID={drawerState.class_id}/>
            </Drawer>
        </>
    )

}

export default ManageUserPage