import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Btn, H4} from "../../AbstractElements";
import React, {useEffect, useState} from "react";
import {handlerSetFormString} from "../../Utils/forms";
import axios from "axios";
import {API_COURSE_MODULE_GROUPS} from "../../api/service";
import {CreateFilterEncoding, CreatePaginationEncoding} from "../../Utils/Base64/request";
import SweetAlert from "sweetalert2";

export const CourseModulesGroupForms = ({
                                            id = 0,
                                            courseID = 0,
                                            refreshState,
                                            setRefreshState,
                                            drawerState,
                                            setDrawerState
                                        }) => {
    const initialData = {
        title: "",
        course_id: courseID
    }
    const [data, setData] = useState(initialData)

    useEffect(() => {
        if (id !== 0) {
            axios.get(API_COURSE_MODULE_GROUPS + "/?pagination=" + CreatePaginationEncoding(1, 0) + "&filter=" + CreateFilterEncoding({
                id: id
            })).then(r => {
                if (r.status === 200) {
                    return r.data["data"]
                }
            }).then(json => {
                if (json.data) {
                    json.data.length > 0 && setData(json.data[0])
                }
            })
        } else {
            setData(initialData)
        }
    }, [id])

    useEffect(() => {
        setData({
            ...initialData,
            course_id: courseID
        })
    }, [courseID])

    const handlerSubmit = (e) => {
        e.preventDefault()
        if (id === 0) {
            axios.post(API_COURSE_MODULE_GROUPS + "/", {...data}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {

                        setRefreshState({
                            ...refreshState,
                            courseModuleGroupDataTable: true
                        })
                    })
                } else {
                    throw r
                }
            }).catch((err) => {
                SweetAlert.fire({
                    title: 'Failed!',
                    text: 'Data Saving Failed!',
                    icon: 'error'
                })
            })
        } else {
            axios.put(API_COURSE_MODULE_GROUPS + "/", {...data}).then(r => {
                if (r.status === 204) {
                    SweetAlert.fire({
                        title: 'Success!',
                        text: 'Data Saving Completed!',
                        icon: 'success'
                    }).then(() => {
                        setRefreshState({
                            ...refreshState,
                            courseModuleGroupDataTable: true
                        })
                    })
                } else {
                    throw r
                }
            })
                .catch((err) => {
                    SweetAlert.fire({
                        title: 'Failed!',
                        text: 'Data Saving Failed!',
                        icon: 'error'
                    })
                })
        }
    }

    return <>
        <div className="m-b-30">
            <H4>{id !== 0 ? "Edit" : "Create"} Course Exam</H4>
            <hr/>
        </div>
        <Form className="form theme-form" onSubmit={handlerSubmit}>
            <Row>
                <Col>
                    <FormGroup>
                        <Label>Exam Name</Label>
                        <Input required name="title" value={data.title}
                               onChange={(e) => handlerSetFormString(e, data, setData)}
                               className="form-control btn-square" type="text"
                               placeholder="Type Something"/>
                    </FormGroup>
                </Col>
            </Row>
            <Btn attrBtn={{
                color: 'primary',
                className: 'm-r-15',
                type: 'submit',
            }}>
                Submit
            </Btn>
        </Form>
    </>
}